<template>
  <validation-observer  ref="observer" tag="form">
    <b-row v-if="showHeading">
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Application Details</h5>
        <small class="text-muted">Enter Application Details.</small>
      </b-col>
    </b-row>
<!--    <b-overlay :show="state.loading" variant="white" opacity="1" :rounded="true">-->
      <b-row v-if="!state.loading">
        <b-col md="12">
          <b-row>
            <b-col align-self="center">
              <validation-provider #default="{ errors }" name="Festival" rules="required">
                <b-form-group label="Festival" label-for="festival" :state="errors.length > 0 ? false:null">
                  <v-select v-model="application.festival"
                            input-id="festival"
                            :options="festivals" label="name"
                            :loading="options.festivals.loading"
                            :filter="festivalsFilter"
                            :reduce="(f) => ({ id: f.id, name: f.name })"
                            :selectable="option => option.state.enabled === true"
                            :select-on-tab="true"
														:append-to-body="false"
                            :disabled="!editable"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            @input="emitFestival"
                  >
                    <template #option="{ name, site, zone, state }">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <h6 class="mb-0">Festival {{ name }}</h6>
                          <small v-if="zone">{{ zone.name }} {{ site ? ` | ${ site }` : null }}</small>
                          <small v-else class="text-danger">No Zone {{ site ? ` | ${ site }` : null }}</small>
                        </div>
                        <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                      </div>
                    </template>

                    <template #selected-option="{ name, site }">
                      Festival {{ name }} {{ site ? ` - ${ site }` : null }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="userHasSchoolZone || tourProps.festivalsBtn.show" cols="auto" align-self="center">
              <b-form-group id="festivals-btn" label="Showing" label-for="festival-cb">
                <b-checkbox id="festival-cb"
                            v-model="options.festivals.showAll"
                            :disabled="!editable"
                            button button-variant="outline-primary">
                  {{ options.festivals.showAll ? 'All Festivals' : 'My Festivals' }}
                </b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <validation-provider #default="{ errors }" name="Form" rules="required">
            <b-form-group label="Form" label-for="form" :state="errors.length > 0 ? false:null">
              <v-select v-model="application.form"
                        input-id="form"
                        :options="forms" label="name"
                        :loading="options.forms.loading"
                        :reduce="(f) => ({ id: f.id, name: f.name, type: f.type })"
                        :selectable="option => option.state.enabled === true"
                        :select-on-tab="true"
                        :append-to-body="false"
                        :disabled="!editable"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @option:selecting="application.instrument = null"
                        @input="emitForm">
                <template #option="{ name, state }">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>{{ name }}</div>
                    <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                  </div>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider #default="{ errors }" name="Instrument" rules="required">
            <b-form-group label="Instrument" label-for="instrument" :state="errors.length > 0 ? false:null">
              <v-select v-model="application.instrument"
                        input-id="instrument"
                        :options="instruments" label="name"
                        :loading="options.forms.loading"
                        :reduce="(i) => ({ id: i.id, name: i.name })"
                        :selectable="option => option.state.enabled === true"
                        :select-on-tab="true"
                        :append-to-body="false"
                        :disabled="!editable || application.form === null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="emitInstrument">
                <template #option="{ name, state }">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>{{ name }}</div>
                    <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                  </div>
                </template>

              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
    </b-row>
<!--    </b-overlay>-->
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { TabContent } from 'vue-form-wizard'
import {API, graphqlOperation} from 'aws-amplify';
import {
  listFestivals,
  listForms,
  listInstruments,
  onUpdateFestival,
  onUpdateForm,
  onUpdateInstrument
} from '@/graphql/queries/application';
import notify from '@/mixins/notify.mixin';
import Fuse from 'fuse.js';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'ApplicationDetails',
  components: {
    TabContent,
    vSelect
  },
  mixins: [ notify, settingsMixin ],
  props: {
    currentUser: {
      type: Object,
      default: null
    },
    festival: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      default: null
    },
    instrument: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    showHeading: {
        type: Boolean,
        default: true
    },
    loadDataOnCreate: {
      type: Boolean,
      default: true
    },
    tourProps: {
      type: Object,
      default: () => ({
        festivalsBtn: {
          show: false
        },
      })
    }
  },
  data() {
    return {
      user: this.currentUser,
      application: {
        festival: this.festival,
        form: this.form,
        instrument: this.instrument
      },
      options: {
        festivals: {
          items: [],
          loading: false,
          loaded: false,
          subscription: null,
          showAll: false,
        },
        forms: {
          items: [],
          loading: false,
          loaded: false,
          subscription: null,
        },
        instruments: {
          items: [],
          loading: false,
          loaded: false,
          subscription: null,
        },
      },
      state: {
        loading: true
      }
    }
  },
  computed: {
    forms() {
        if(this.options.forms.loading) return []
        if(this.editable && this.options.forms.loaded === false) {
            this.listForms()
            this.onUpdateFestival()
            return []
        }
        return this.options.forms.items
    },
    festivals() {
      if(this.options.festivals.loading) {
        return []
      }
      if(this.editable && this.options.festivals.loaded === false && this.settingsStore.app) {
          this.listFestivals()
          this.onUpdateFestival()
          return []
      }
      if(this.userHasSchoolZone && this.options.festivals.showAll === false) {
          return this.options.festivals.items.filter(festival => this.user.schools.items.map(item => item.school.schoolZoneId).includes(festival.festivalZoneId))
      }
      return this.options.festivals.items
    },
    instruments() {
      if(this.options.instruments.loading) return []
      if(this.editable && this.options.instruments.loaded === false) {
          this.listInstruments()
          this.onUpdateInstrument()
          return []
      }
      if(this.application.form) {
        return this.options.instruments?.items?.filter(instrument => instrument?.formInstrumentsId === this.application.form?.id)
      }
      return []
    },
    userHasSchoolZone() {
      return this.user?.schools?.items.some(item => item.school.schoolZoneId) || false
    },
  },
  async created() {
    this.state.loading = false
  },
  beforeDestroy() {
    if(this.options.festivals?.subscription) { this.options.festivals.subscription.unsubscribe() }
    if(this.options.forms?.subscription) { this.options.forms.subscription.unsubscribe() }
    if(this.options.instruments?.subscription) { this.options.instruments.subscription.unsubscribe() }
  },
  methods: {
    async listFestivals(nextToken, pagedItems) {
      this.options.festivals.loading = true
      const items = pagedItems || []
      const input = {
        limit: 500,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listFestivals, input));
      items.push(...response.data.listFestivals.items);

      if(response.data.listFestivals.nextToken) {
        await this.listFestivals(response.data.listFestivals.nextToken, items)
      }
      else {
        this.options.festivals.items = items
            .sort((a, b) => a.zone?.name.localeCompare(b.zone?.name, 'en', { numeric: true })
                || a.name.localeCompare(b.name, 'en', { numeric: true }) );
        this.options.festivals.loading = false
        this.options.festivals.loaded = true
      }
    },
    async listForms() {
      this.options.forms.loading = true
      const response = await API.graphql(graphqlOperation(listForms));
      this.options.forms.items = response.data.listForms.items.sort((a, b) => a.name.localeCompare(b.name));
      //this.options.forms.items.forEach(item => item.instruments.items.sort((a, b) => a.name.localeCompare(b.name)))
      this.options.forms.loading = false
      this.options.forms.loaded = true
    },
    async listInstruments() {
      this.options.instruments.loading = true
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.options.instruments.items = response.data.listInstruments.items.sort((a, b) => a.name.localeCompare(b.name));
      //this.options.instruments.items.forEach(item => item.instruments.items.sort((a, b) => a.name.localeCompare(b.name)))
      this.options.instruments.loading = false
      this.options.instruments.loaded = true
    },

    async onUpdateFestival() {
      this.options.festivals.subscription = API.graphql(graphqlOperation(onUpdateFestival)).subscribe((sourceData) => {
        this.syncNotification()
        const festival = sourceData.value.data.onUpdateFestival
        if (festival && this.options.festivals.items.map(item => item.id).includes(festival.id)) {
          this.options.festivals.loading = true
          const index = this.options.festivals.items.findIndex(item => item.id === festival.id)
          if(index > -1) {
            this.options.festivals.items.splice(index, 1, festival)
          }
          this.options.festivals.loading = false
        }
      });
    },
    async onUpdateForm() {
      this.options.forms.subscription = API.graphql(graphqlOperation(onUpdateForm)).subscribe((sourceData) => {
        this.syncNotification()
        const form = sourceData.value.data.onUpdateForm
        if (form && this.options.forms.items.map(item => item.id).includes(form.id)) {
          this.options.forms.loading = true
          const index = this.options.forms.items.findIndex(item => item.id === form.id)
          if(index > -1) {
            this.options.forms.items.splice(index, 1, form)
          }
          this.options.forms.loading = false
        }
      });
    },
    async onUpdateInstrument() {
      this.options.instruments.subscription = API.graphql(graphqlOperation(onUpdateInstrument)).subscribe((sourceData) => {
        this.syncNotification()
        const instrument = sourceData.value.data.onUpdateInstrument
        if (instrument && this.options.instruments.items.map(item => item.id).includes(instrument.id)) {
          this.options.instruments.loading = true
          const index = this.options.instruments.items.findIndex(item => item.id === instrument.id)
          if(index > -1) {
            this.options.instruments.items.splice(index, 1, instrument)
          }
          this.options.instruments.loading = false
        }
      });
    },

    emitFestival(value) {
      this.$emit('update:festival', value)
    },
    emitForm(value) {
      if(!value) {
        this.application.instrument = null
        this.$emit('update:instrument', null)
      }
      this.$emit('update:form', value)
    },
    emitInstrument(value) {
      this.$emit('update:instrument', value)
    },

    festivalsFilter(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name', 'site', 'zone.name'],
        shouldSort: true,
        threshold: 0.2
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
  }
}
</script>

<style scoped>

</style>
