/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Invoice may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Payments associated with this invoice will be deleted.<br/>
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Invoice was successfully deleted.' }
}

export async function cascadeDeleteInvoice(invoiceId, swalCallback) {
    try {
        /** Update Student record associated to this Invoice **/
        await updateStudent(invoiceId, swalCallback)
        
        /** Delete Payment records associated to this Invoice **/
        const payments = await listPayments(invoiceId, swalCallback)
        await batchProcessWithRetry(payments, async (payment) => {
            await API.graphql(graphqlOperation(deletePaymentMutation, { input: { id: payment.id }} ));
        }, { title: 'Deleting Payments', callback: swalCallback })

        /** Delete Invoice **/
        await deleteInvoice(invoiceId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteInvoice(invoiceId, swalCallback) {
    const title = 'Deleting Invoice'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteInvoiceMutation, { input: { id: invoiceId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function updateStudent(invoiceId, swalCallback) {
    swalCallback(new SwalData('Loading Student', 0, 1))

    /** Get the Invoice record in order to get the Student ID **/
    const response = await API.graphql(graphqlOperation(getInvoiceQuery, { id: invoiceId } ));
    const invoice = response.data.getInvoice;

    /** If the Invoice has a Student ID, we need to update the Student record, removing the invoice reference **/
    if(invoice && invoice.invoiceStudentId) {
        swalCallback(new SwalData('Loading Student', 1, 1))
        await new Promise(resolve => setTimeout(resolve, 1000));
        swalCallback(new SwalData('Updating Student', 0, 1))
        await API.graphql(graphqlOperation(updateStudentMutation, { input: { id: invoice.invoiceStudentId, studentInvoiceId: null }} ));
        await new Promise(resolve => setTimeout(resolve, 1000));
        swalCallback(new SwalData('Updating Student', 1, 1))
    }
}


async function listPayments(invoiceId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { invoicePaymentsId: { eq: invoiceId } } }
            const response = await API.graphql(graphqlOperation(listPaymentsQuery, input));
            items.push(...response.data.listPayments.items)
            nextToken = response.data.listPayments.nextToken;
            swalCallback(new SwalData('Loading Payments', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}


/** Queries & Mutations **/

const getInvoiceQuery = /* GraphQL */ `
    query GetInvoice($id: ID!) {
        getInvoice(id: $id) {
            id
            invoiceStudentId
        }
    }
`;

const updateStudentMutation = /* GraphQL */ `
    mutation UpdateStudent(
        $input: UpdateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        updateStudent(input: $input, condition: $condition) {
            id
            studentInvoiceId
        }
    }
`;

const listPaymentsQuery = /* GraphQL */ `
    query ListPayments(
        $filter: ModelPaymentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                invoicePaymentsId
            }
            nextToken
        }
    }
`;

const deletePaymentMutation = /* GraphQL */ `
    mutation DeletePayment(
        $input: DeletePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        deletePayment(input: $input, condition: $condition) {
            id
        }
    }
`;

const deleteInvoiceMutation = /* GraphQL */ `
    mutation DeleteInvoice(
        $input: DeleteInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        deleteInvoice(input: $input, condition: $condition) {
            id
        }
    }
`;
