<template>
  <b-card no-body class="border shadow-sm">
    <b-card-header class="d-block">
      <b-row>
        <b-col>
          <span class="app-label">
            List Previous NYSSMA (Spring) Solo Ratings
          </span>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="1" align-self="start">
          <span class="app-label">9th Grade:</span>
        </b-col>
        <b-col>
          <validation-provider #default="{ errors }" name="Level" rules="">
            <b-form-group label="Level" label-for="level-9" :state="errors.length > 0 ? false:null">
              <v-select v-model="question.previousSoloRating.ninth.level"
                        input-id="level-9"
                        :options="options.levels.ninth"
                        :select-on-tab="true"
                        :disabled="!editable"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="onNinthLevelInput" />

              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group :label="`Score (${gradeRangeNinth.min}-${gradeRangeNinth.max})`" label-for="grade-9">
            <validation-provider #default="{ errors }" name="Grade" :rules="{ min_value: gradeRangeNinth.min, max_value: gradeRangeNinth.max }">
              <b-input id="grade-9"
                       v-model="question.previousSoloRating.ninth.grade" number
                       placeholder="0"
                       :state="errors.length > 0 ? false:null"
                       :disabled="!editable || question.previousSoloRating.ninth.level === null"
                       @input="syncQuestion" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col cols="1" align-self="start">
          <span class="app-label">10th Grade:</span>
        </b-col>
        <b-col>
          <validation-provider #default="{ errors }" name="Level" rules="">
            <b-form-group label="Level" label-for="level-10" :state="errors.length > 0 ? false:null">
              <v-select v-model="question.previousSoloRating.tenth.level"
                        input-id="level-10"
                        :options="options.levels.tenth"
                        :select-on-tab="true"
                        :disabled="!editable"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="onTenthLevelInput" />

              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-form-group :label="`Score (${gradeRangeTenth.min}-${gradeRangeTenth.max})`" label-for="grade-10">
            <validation-provider #default="{ errors }" name="Grade" :rules="{ min_value: gradeRangeTenth.min, max_value: gradeRangeTenth.max }">
              <b-input id="grade-10"
                       v-model="question.previousSoloRating.tenth.grade" number
                       placeholder="0"
                       :state="errors.length > 0 ? false:null"
                       :disabled="!editable || question.previousSoloRating.tenth.level === null"
                       @input="syncQuestion" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>

  </b-card>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'PreviousSoloRatings',
  components: {
    vSelect
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      question: {
        previousSoloRating: {
          ninth: {
            level: this.value?.ninth?.level || null,
            grade: this.value?.ninth?.grade || null
          },
          tenth: {
            level: this.value?.tenth?.level || null,
            grade: this.value?.tenth?.grade || null
          }
        }
      },
      options: {
        levels: {
          ninth: ['1', '2', '3', '4', '5', '6'],
          tenth: ['1', '2', '3', '4', '5', '6', 'All State'],
        }
      }
    }
  },
  computed: {
    gradeRangeNinth() {
      let min = 0; let max = 28;
      if(['5', '6'].includes(this.question.previousSoloRating.ninth.level)) {
        min = 1; max = 100;
      }
      return { min: min, max: max}
    },
    gradeRangeTenth() {
      let min = 0; let max = 28;
      if(['5', '6', 'All State'].includes(this.question.previousSoloRating.tenth.level)) {
        min = 1; max = 100;
      }
      return { min: min, max: max}
    }
  },
  methods: {
    syncQuestion() {
      this.$emit('input', this.question.previousSoloRating)
    },
    onNinthLevelInput(input) {
      if(!input) {
        this.question.previousSoloRating.ninth.grade = null
      }
      this.syncQuestion()
    },
    onTenthLevelInput(input) {
      if(!input) {
        this.question.previousSoloRating.tenth.grade = null
      }
      this.syncQuestion()
    },
  }
}
</script>

<style scoped>

</style>
