<template>
  <b-row>
    <b-col cols="12">
      <slot name="alerts"></slot>
    </b-col>
    <transition name="fade">
      <b-col v-if="showSidebar"
             :class="[
                 'd-print-none',
                  'col-12 col-md-4 col-xl-4 col-xxl-4 col-xxxl-25 col-xxxxl-20'
             ]">
        <slot name="sidebar"></slot>
      </b-col>
    </transition>
    <b-col :class="[
        showSidebarRight
        ? 'col-12 col-md-8 col-xl-8 col-xxl-8 col-xxxl-auto col-xxxxl-auto'
        : 'col-12 col-md-8 col-xl-8 col-xxl-8 col-xxxl-75 col-xxxxl-80'
    ]">
      <slot v-if="hasId" name="content"></slot>
      <slot v-else name="empty">
        <div class="d-flex flex-column align-items-center">
          <b-img :src="emptyImage" alt="Bus" fluid class="d-block mx-auto img"/>
          <div class="display-4">Nothing Selected</div>
          <div>{{ word }}</div>
        </div>
      </slot>
    </b-col>
    <transition name="fade">
      <b-col v-if="showSidebarRight" :class="[
          'd-print-none pt-2 pt-xxxl-0',
          'col-12 col-md-4 col-xl-4 col-xxl col-xxxl col-xxxxl-20'
      ]">
        <slot name="sidebar-right"></slot>
      </b-col>
    </transition>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import {ref} from '@vue/composition-api';
import {rand, useIntervalFn} from '@vueuse/core';

export default {
  name: 'EventLayout',
  props: {
    showSidebar: {
      type: Boolean,
      default: true
    },
    showSidebarRight: {
      type: Boolean,
      default: false
    },
    autoToggleSidebar: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const emptyImage = require('@/assets/images/illustration/storyset-ui-ux-design-rafiki.svg')
    const greetings = ['Hello', 'Hi', 'Yo!', 'Hey', 'Hola', 'こんにちは', 'Bonjour', 'Salut!', '你好', 'Привет']
    const word = ref('Hello')
    const interval = ref(4000)
    const { pause, resume, isActive } = useIntervalFn(() => {
      word.value = greetings[rand(0, greetings.length - 1)]
    }, interval)


    return {
      word,
      emptyImage
    }
  },
  computed: {
    hasId() {
      return !!this.$route.params.id
    }
  },
  watch: {
    '$route.params.id'(id) {
      if(this.autoToggleSidebar) {
        if (id) {
          this.showSidebar = false
        }
        else {
          this.showSidebar = true
        }
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* Starting and ending state for entering/exiting */ {
  opacity: 0;
}
.img {
  max-height: 600px;
  max-width: 600px;
}

</style>
