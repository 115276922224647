var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border shadow-sm",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-block"},[_c('b-row',[_c('b-col',[_c('span',{staticClass:"app-label"},[_vm._v(" List Previous NYSSMA (Spring) Solo Ratings ")])])],1)],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"1","align-self":"start"}},[_c('span',{staticClass:"app-label"},[_vm._v("9th Grade:")])]),_c('b-col',[_c('validation-provider',{attrs:{"name":"Level","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Level","label-for":"level-9","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"input-id":"level-9","options":_vm.options.levels.ninth,"select-on-tab":true,"disabled":!_vm.editable,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.onNinthLevelInput},model:{value:(_vm.question.previousSoloRating.ninth.level),callback:function ($$v) {_vm.$set(_vm.question.previousSoloRating.ninth, "level", $$v)},expression:"question.previousSoloRating.ninth.level"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":("Score (" + (_vm.gradeRangeNinth.min) + "-" + (_vm.gradeRangeNinth.max) + ")"),"label-for":"grade-9"}},[_c('validation-provider',{attrs:{"name":"Grade","rules":{ min_value: _vm.gradeRangeNinth.min, max_value: _vm.gradeRangeNinth.max }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"grade-9","number":"","placeholder":"0","state":errors.length > 0 ? false:null,"disabled":!_vm.editable || _vm.question.previousSoloRating.ninth.level === null},on:{"input":_vm.syncQuestion},model:{value:(_vm.question.previousSoloRating.ninth.grade),callback:function ($$v) {_vm.$set(_vm.question.previousSoloRating.ninth, "grade", $$v)},expression:"question.previousSoloRating.ninth.grade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"1","align-self":"start"}},[_c('span',{staticClass:"app-label"},[_vm._v("10th Grade:")])]),_c('b-col',[_c('validation-provider',{attrs:{"name":"Level","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Level","label-for":"level-10","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"input-id":"level-10","options":_vm.options.levels.tenth,"select-on-tab":true,"disabled":!_vm.editable,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.onTenthLevelInput},model:{value:(_vm.question.previousSoloRating.tenth.level),callback:function ($$v) {_vm.$set(_vm.question.previousSoloRating.tenth, "level", $$v)},expression:"question.previousSoloRating.tenth.level"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":("Score (" + (_vm.gradeRangeTenth.min) + "-" + (_vm.gradeRangeTenth.max) + ")"),"label-for":"grade-10"}},[_c('validation-provider',{attrs:{"name":"Grade","rules":{ min_value: _vm.gradeRangeTenth.min, max_value: _vm.gradeRangeTenth.max }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"grade-10","number":"","placeholder":"0","state":errors.length > 0 ? false:null,"disabled":!_vm.editable || _vm.question.previousSoloRating.tenth.level === null},on:{"input":_vm.syncQuestion},model:{value:(_vm.question.previousSoloRating.tenth.grade),callback:function ($$v) {_vm.$set(_vm.question.previousSoloRating.tenth, "grade", $$v)},expression:"question.previousSoloRating.tenth.grade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }