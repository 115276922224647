<template>
  <b-input-group class="flex-nowrap">
    <template v-if="value.drag">
      <draggable v-model="value.by" class="p-50 flex-grow-1 rounded-left sorting-btn-border bg-white" @end="emitInput">
        <b-badge v-for="(item, index) in value.by" :key="index" variant="light-primary" class="mr-50 font-small-3 font-weight-normal cursor-move">
          <b-icon-grip-vertical class="my-auto"/>
          {{ value.options.find(option => option.value === item).label }}
        </b-badge>
      </draggable>
    </template>
    <template v-else>
      <v-select v-model="value.by" multiple
                :options="value.options" label="label"
                :reduce="option => option.value"
                input-id="sorting-by" class="h-100"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                @input="emitInput"/>
    </template>
    <b-input-group-append>
      <b-button v-if="value.by.length > 1" size="sm" variant="white" class="sorting-btn-border border-left- bg-white" title="Selected Order" @click="toggleDrag">
        <b-icon-grip-vertical />
      </b-button>
      <b-button size="sm" variant="white" class="sorting-btn-border bg-white" title="Sort Order" @click="toggleSortOrder">
        <b-icon-sort-alpha-down v-if="!value.desc"/>
        <b-icon-sort-alpha-down-alt v-else/>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import vSelect from 'vue-select';
import draggable from 'vuedraggable';

export default {
  name: 'SortInput',
  components: {
    vSelect,
    draggable
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        by: [],
        desc: false,
        drag: false,
        options: []
      })
    }
  },
  methods: {
    emitInput() {
      this.$emit('input', this.value);
    },
    toggleDrag() {
      this.value.drag = !this.value.drag;
      this.emitInput();
    },
    toggleSortOrder() {
      this.value.desc = !this.value.desc;
      this.emitInput();
    }
  }
}
</script>

<style scoped>
.sorting-btn-border {
  border: 1px solid #d8d6de;
}
</style>
