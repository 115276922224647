<template>
  <b-card no-body class="border shadow-sm">
    <b-card-header class="d-block">
      <b-row>
        <b-col>
          <span class="app-label">
            Instrument Experience
          </span>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <validation-provider #default="{ errors }" name="Years Experience" rules="numeric">
            <b-form-group label="Years on Present Instrument?" label-for="yearsExp" :invalid-feedback="errors[0]">
              <b-input id="yearsExp"
                       v-model="question.instrument.years"
                       :state="errors.length > 0 ? false:null"
                       :disabled="!editable"
                       @input="syncQuestion" />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <label for="doubles">Doubles?</label>
          <validation-provider #default="{ errors }" name="Doubles" rules="">
            <b-input id="doubles"
                     v-model="question.instrument.doubles"
                     :state="errors.length > 0 ? false:null"
                     :disabled="!editable"
                     @input="syncQuestion" />
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'ExperienceInstrument',
  props: {
    value: {
      type: Object,
      default: null
    },
    labelText: {
      type: String,
      default: '',
      required: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      question: {
        instrument: {
          years: this.value?.years || null,
          doubles: this.value?.doubles || null
        },
      },
    }
  },
  methods: {
    syncQuestion() {
      this.$emit('input', this.question.instrument)
    },
  }
}
</script>

<style scoped>

</style>
