<template>
  <b-modal :id="id" :title="id" size="xl" lazy centered
           body-class="w-100" hide-header hide-footer @show="load">
    <template #default>
      <b-overlay :show="true" bg-color="white" opacity="1" style="min-height: 100px;">
        <template v-if="!studentEvents.loading">
            <b-table-simple v-for="chaperone in chaperones" :key="chaperone.id" responsive caption-top class="d-none d-print-block">
              <caption>
                <div class="d-flex justify-content-center gap-1">
                  <b-img :src="logo" class="print-logo" alt="Logo"/>
                  <div class="text-body d-flex flex-column align-self-center">
                    <div class="font-weight-bold font-large-1">Bed Check Roster</div>
                    <div class="font-weight-bold font-medium-1">All-State - {{ $store.getters['settings/getCurrentYear'] }}</div>
                  </div>
                </div>
              </caption>
              <b-thead>
                <b-tr :data="fullName = getFullName(chaperone)">
                  <b-th colspan="4" class="font-weight-bolder font-medium-1 py-0" :class="{'text-danger': !fullName}">{{ fullName ? fullName : 'Unnamed Chaperone' }}</b-th>
                  <b-th colspan="4" class="font-weight-bolder font-medium-1 text-right py-0" :class="{'text-danger': (!chaperone.hotelId || !chaperone.hotel || !chaperone.hotel.name)}">
                    <template v-if="!chaperone.hotelId || !chaperone.hotel">No Hotel</template>
                    <template v-else-if="!chaperone.hotel.name">Unnamed Hotel</template>
                    <template v-else>
                      {{ chaperone.hotel.name }}
                    </template>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th colspan="8" class="pb-2">{{ studentEvents.items.filter(studentEvent => studentEvent.chaperoneId === chaperone.id).length || 0 }} Students</b-th>
                </b-tr>
                <b-tr>
                  <b-th class="col-10-percent">Room</b-th>
                  <b-th class="col-20-percent">Student</b-th>
                  <b-th class="col-10-percent">Sex</b-th>
                  <b-th class="col-10-percent">Gender</b-th>
                  <b-th class="col-20-percent">Ensemble</b-th>
                  <b-th class="col-10-percent text-center">Thursday</b-th>
                  <b-th class="col-10-percent text-center">Friday</b-th>
                  <b-th class="col-10-percent text-center">Saturday</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="event in getStudentEventsForChaperone(chaperone)" :key="event.id"
                      :data="application = event.student ? getApplicationWithSelection(event.student.applications) : null">
                  <b-td>
              <span v-if="event.room">
                 {{ event.room }}
              </span>
                    <span v-else class="text-danger">
                No Room
              </span>
                  </b-td>
                  <b-td>
              <span v-if="event.student">
                {{ getFullName(event.student, { lnf: true }) }}
              </span>
                    <span v-else class="text-danger">
                No Student
              </span>
                  </b-td>
                  <b-td>
              <span v-if="event.student">
                 {{ event.student.sex }}
              </span>
                    <span v-else class="text-danger">
                Unknown Sex
              </span>
                  </b-td>
                  <b-td>
              <span v-if="event.student">
                 {{ event.student.gender }}
              </span>
                    <span v-else class="text-danger">
                Unknown Gender
              </span>
                  </b-td>
                  <b-td>
              <span v-if="application && application.selection && application.selection.ensemble">
                 {{ application.selection.ensemble.name }}
              </span>
                    <span v-else class="text-danger">
                Unknown Ensemble
              </span></b-td>
                  <b-td class="text-center">
                    <b-icon :icon="event.checkin.thursday ? 'check-square' : 'square'"/>
                  </b-td>
                  <b-td class="text-center">
                    <b-icon :icon="event.checkin.friday ? 'check-square' : 'square'"/>
                  </b-td>
                  <b-td class="text-center">
                    <b-icon :icon="event.checkin.saturday ? 'check-square' : 'square'"/>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </template>
        <template v-else>
          <b-card class="shadow-none m-0 p-0">
            <b-card-body>
              <!-- Card needed to give overlay some dimension -->
            </b-card-body>
          </b-card>
        </template>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';


import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import popper from '@/mixins/popper.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';
import settingsMixin from '@/mixins/settings.mixin';
import { getStudentEvents } from '@/views/events/service';
import events from '@/mixins/event.mixin';

export default {
  name: 'CheckinModal',
  components: {
    TableRowOptions,
    BCardActions,
    ApplicationGrading,
    ApplicationDetails,
    ApplicationStudent,
    ApplicationQuestions,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper, settingsMixin, events],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    chaperones: {
      type: Array,
      required: true,
      default: () => []
    },
    events: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Object,
      default: () => ({
        by: ['room', 'student.name.last', 'student.name.first'],
        desc: false,
        options: [
          { label: 'Room', value: 'room'},
          { label: 'First Name', value: 'student.name.first'},
          { label: 'Last Name', value: 'student.name.last'},
        ]
      })
    }
  },
  data() {
    /* eslint-disable global-require */
    return {
      logo: null,
      user: null,
      instrument: null,
      ensembles: {
        loading: true,
        items: []
      },
      studentEvents: {
        loading: true,
        items: [ ],
      },
      applications: {
        loading: false,
        loaded: 0
      },
      table: {
        busy: false,
        fields: [
          /*{ key: 'group', label: 'Group', class: 'page-break' },*/
          { key: 'student', label: 'Student', class: 'col-student' },
          { key: 'festival.name', label: 'Festival', class: 'col-festival' },
          { key: 'festival.zone.name', label: 'Zone', class: 'col-zone' },
          { key: 'ranking.score', label: 'Score', class: 'col-score' },
          { key: 'ranking.local', label: 'Local Rank', class: 'col-local' },
          { key: 'selection.ranking.state', label: 'State Rank', class: 'col-state' },
          { key: 'selection.ensemble', label: 'Ensemble', class: 'col-ensemble' },
          { key: 'selection.part', label: 'Part', class: 'col-part' },
          /*{ key: 'row-options', label: '', tdClass: 'align-middle', thClass: 'border-0', class: 'table-row-options' },*/
        ],
        filter: {
          group: null,
          ensemble: null,
          part: null
        },
        search: null,
        items: [],
        initialItems: [],
        subscription: {
          onUpdate: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showLeftColumn: true,
        },
        changes: {
          expanded: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false
        },
        error: {
          show: false,
          message: ''
        }
      },
      rules: {
        state: { required: false, numeric: true, min_value: 0, max_value: 999 },
        ensemble: { required: false },
        part: { required: false },
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
    sortedItems() {
      if(this.table.busy || this.table.items.length === 0) {
        return []
      }
      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'group',
          'selection.ensemble',
          'selection.part',
          'student.id',
          'student.name.full',
        ]
      })

      const query = { $and: [ ] }
      if(this.table.filter.group) { query.$and.push({group: `'${this.table.filter.group}` }) }
      if(this.table.filter.ensemble) { query.$and.push({'selection.ensemble': `'${this.table.filter.ensemble}` }) }
      if(this.table.filter.part) { query.$and.push({'selection.part': `'${this.table.filter.part}` }) }

      if(this.table.search) {
        query.$and.push({
          $or: [
            { id: `'${this.table.search}` },
            { 'student.id': `'${this.table.search}` },
            { 'student.name.full': `'${this.table.search}`}
          ]
        })
      }
      let items;
      if(query.$and.length) {
        items = fuse.search(query).map(({ item }) => item).sort(this.compareItems())
      }
      else {
        items = this.table.items.slice().sort(this.compareItems())
      }

      return items.map((item, index) => ({...item,
        tabIndex: {
          state: (index + 1000),
          ensemble: (index + 1000) + this.table.items.length,
          part: (index + 1000) + (this.table.items.length * 2)
        }
      }))
    },
  },
  watch: {
    events: {
      deep: true,
      handler(value) {
        this.studentEvents.items = value
      }
    },
  },
  mounted() {
    window.addEventListener('afterprint', this.handleAfterPrint);
  },
  beforeDestroy() {
    window.removeEventListener('afterprint', this.handleAfterPrint);
  },
  methods: {
    async refresh() {
      await this.listStudentEvents()
    },
    async load() {
      if(!this.events.length) {
        await this.listStudentEvents()
      }
      else {
        this.studentEvents.items = this.events
        this.studentEvents.loading = false
      }

      if(!this.logo) {
        this.logo = require('@/assets/images/logo/logo5-blue-white-gold.svg');
      }

      await this.$nextTick(() => {
        setTimeout(() => {
          window.print()
        }, 500);
      })
    },
    async listStudentEvents() {
      this.studentEvents.loading = true;
      await getStudentEvents().then(students => {
        this.studentEvents.items = students;
        this.studentEvents.loading = false;
        this.studentEvents.loaded = true;
      })
    },
    getStudentEventsForChaperone(chaperone) {
      return this.sortItems(this.sorting, this.studentEvents.items.filter(studentEvent => studentEvent.chaperoneId === chaperone.id))
    },

    handleAfterPrint() {
      this.$bvModal.hide(this.id)
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
  }
}
</script>

<style lang="scss">
@media print {
  .print-logo {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
  }

  #app {
    visibility: hidden;
    display: none;
  }

  table {
    page-break-after: always;
    break-after: page;

    * {
      font-size: 11px;
    }

    caption {
      display: table-caption;
      margin: 0 auto 2rem auto;
    }

    thead {
      page-break-before:unset!important;
      page-break-inside: unset!important;
      page-break-after:unset!important;
    }
  }

  .modal {
    padding-left: 0 !important;
    position: relative;

    .modal-dialog {
      width: 100vw;
      max-width: none;
      margin: 0;

      span[tabindex] {
        visibility: hidden;
        display: none;
      }

      .modal-content {
        height: 100%;
        border: 0;

        .modal-body {
          overflow-y: visible;

          .b-overlay-wrap {
            overflow: hidden;

            .b-overlay {
              visibility: hidden;
              display: none;
            }
          }
        }
      }

      .modal-footer {
        visibility: hidden;
        display: none;
      }
    }
  }

  .modal-backdrop {
    visibility: hidden;
    display: none;
  }
}
</style>


