<template>
  <b-overlay :show="selected.chaperone.studentEvents.loading" :opacity="1" bg-color="white" style="min-height: 100px">
    <template #overlay>
      <div class="d-flex flex-column align-items-center gap-1">
        <b-spinner/>
        <div>{{ state.saving ? 'Saving' : 'Loading' }}</div>
      </div>
    </template>
    <template #default>
      <b-card>
        <b-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-6 px-0">
              <v-select v-model="search"
                        :options="selected.chaperone.studentEvents.items"
                        :filter-by="(option, label, search) =>
                                            option.student.name.first.toLowerCase().includes(search.toLowerCase())
                                            || option.student.name.last.toLowerCase().includes(search.toLowerCase())"
                        select-on-tab
                        label="id"
                        :reduce="option => option.id"
                        placeholder="Search..."
                        class="w-100">
                <template #option="{ student }">
                  {{ getFullName(student) }}
                </template>
                <template #selected-option="{ student }">
                  {{ getFullName(student) }}
                </template>
              </v-select>
            </div>
            <div>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.grouped.show = !ui.grouped.show">
                <b-icon :icon="grouped.by ? 'diagram3-fill' : 'diagram3'" style="width: 15px; height: 15px"/>
              </b-button>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.sorting.show = !ui.sorting.show">
                <b-icon-sort-down style="width: 14px; height: 14px"/>
              </b-button>
              <b-button size="sm" variant="link" class="btn-icon" @click="ui.filters.show = !ui.filters.show">
                <b-icon :icon="isFiltered ? 'funnel-fill' : 'funnel'" style="width: 14px; height: 14px"/>
              </b-button>
            </div>
          </div>
        </b-card-title>

        <b-row>
          <b-col v-if="ui.filters.show" cols="12">
            <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'checkin-filters-collapse'">Filters</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill class="mr-1">{{ activeFilters.length }}</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.filters.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.filters.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="checkin-filters-collapse"
                                       :aria-expanded="ui.filters.expanded ? 'true' : 'false'"
                                       @click="ui.filters.expanded = !ui.filters.expanded "/>
                </div>
              </div>
              <b-collapse id="checkin-filters-collapse" v-model="ui.filters.expanded">
                <b-row class="mt-1">
                  <b-col cols="4">
                    <b-form-group label="Hotel" label-for="filter-hotel">
                      <v-select v-model="filters.hotel"
                                input-id="filter-hotel"
                                placeholder="Hotel"
                                :options="hotels.items" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3">
                        <template #option="{ name }">
                      <span :class="{'text-danger font-small-3': !name}">
                        {{ name ? name : 'Unnamed Hotel' }}
                      </span>
                        </template>
                        <template #selected-option="{ name }">
                      <span :class="{'text-danger': !name}">
                        {{ name ? name : 'Unnamed Hotel' }}
                      </span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Ensemble" label-for="filter-ensemble">
                      <v-select v-model="filters.ensemble"
                                input-id="filter-ensemble"
                                placeholder="Ensemble"
                                :options="ensembles" label="name"
                                :reduce="option => option.id"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100 font-small-3"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                  <b-form-group label="Instrument" label-for="filter-instrument">
                    <v-select v-model="filters.instrument"
                              input-id="filter-instrument"
                              placeholder="Instrument"
                              :options="instruments" label="name"
                              :reduce="option => option.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100 font-small-3"/>
                  </b-form-group>
                </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </b-col>
          <b-col v-if="ui.sorting.show">
            <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'checkin-sorting-collapse'">Sorting</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill class="mr-1">{{ sorting.by.length }}</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.sorting.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.sorting.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="checkin-sorting-collapse"
                                       :aria-expanded="ui.sorting.expanded ? 'true' : 'false'"
                                       @click="ui.sorting.expanded = !ui.sorting.expanded "/>
                </div>
              </div>
              <b-collapse id="checkin-sorting-collapse" v-model="ui.sorting.expanded">
                <b-form-group label="Sort By" label-for="sorting-by" class="mt-1">
                  <sort-input v-model="sorting"/>
                </b-form-group>
              </b-collapse>
            </b-card>
          </b-col>
          <b-col v-if="ui.grouped.show">
            <b-card bg-variant="light-primary" body-class="px-1" class="mb-2">
              <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
                <span v-b-toggle="'checkin-grouped-collapse'">Grouping</span>
                <div class="d-flex align-items-center">
                  <b-badge v-if="grouped.by" title="Active Sorting" variant="primary" pill class="mr-1">1</b-badge>
                  <b-icon-x v-else style="width: 15px; height: 15px;"
                            class="cursor-pointer mr-1"
                            @click="ui.grouped.show = false"/>
                  <b-icon-chevron-down style="width: 14px; height: 14px;"
                                       class="cursor-pointer"
                                       :class="ui.grouped.expanded ? 'rotate-180' : 'collapsed'"
                                       aria-controls="checkin-grouped-collapse"
                                       :aria-expanded="ui.grouped.expanded ? 'true' : 'false'"
                                       @click="ui.grouped.expanded = !ui.grouped.expanded "/>
                </div>
              </div>
              <b-collapse id="checkin-grouped-collapse" v-model="ui.grouped.expanded">
                <b-form-group label="Group By" label-for="group-by" class="mt-1">
                <v-select v-model="grouped.by"
                          input-id="group-by"
                          placeholder="Group By"
                          :options="grouped.options"
                          :reduce="option => ({ valueKey: option.valueKey, textKey: option.textKey, textFn: option.textFn })"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 font-small-3"/>
              </b-form-group>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>

        <template v-if="grouped.by">
          <template v-if="groupedItems">
            <b-card-actions v-for="group in groupedItems" :key="group.key"
                            action-collapse
                            :action-container-classes="{ collapse: 'pr-1'}"
                            header-class="pt-1 bg-light-primary"
                            body-class="pb- bg-light-primary">
              <template #title>
               {{ group.text }}
              </template>
              <template #default>
                <vue-perfect-scrollbar :settings="scrollbarSettings" class="ps-room-assignments">
                  <b-list-group class="rounded p-1 bg-light-primary">
                    <b-list-group-item v-for="(studentEvent, index) in group.items"
                                       :id="studentEvent.id" :key="studentEvent.id"
                                       tag="li"
                                       :action="true"
                                       :to="{ name: 'events-student', params: { id: studentEvent.studentId }}"
                                       :class="['cursor-pointer rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']">
                      <b-row>
                        <b-col cols="auto" align-self="start">
                          <slot name="avatar" :student="studentEvent.student">
                            <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(studentEvent.student)">
                              <font-awesome-icon :icon="getSexIcon(studentEvent.student)" style="width: 22px; height: 22px" />
                              <template #badge>
                                {{ getSexBadge(studentEvent.student) }}
                              </template>
                            </b-avatar>
                          </slot>
                        </b-col>
                        <b-col align-self="center">
                          <slot name="item" :studentEvent="studentEvent">
                            <div class="truncate">
                              <h5 class="mb-25 font-weight-bolder">
                                {{ studentEvent.student.name.first }} {{ studentEvent.student.name.last }}
                              </h5>
                              <div :data="application = getApplicationWithSelection(studentEvent.student.applications)" class="font-small-3">
                                {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                              </div>
                              <div class="font-small-3">
                                <!--                                              {{ getHotelName(studentEvent.hotelId) }} - -->Room {{ studentEvent.room }}
                              </div>
                            </div>
                          </slot>
                        </b-col>
                        <b-col v-for="(_, prop) in studentEvent.checkin" :key="prop" class="col-4 col-xxxl-20 col-qhd-2">
                          <b-row>
                            <b-col align-self="center" cols="auto">
                              <b-avatar :variant="getCheckinVariant(studentEvent.checkin[prop])" size="44px">
                                <font-awesome-icon :icon="['far', getCheckinIcon(studentEvent.checkin[prop])]" style="width: 22px; height: 22px"/>
                              </b-avatar>
                            </b-col>
                            <b-col align-self="center">
                              <b-form-group :label="prop" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold text-capitalize">
                                <v-select v-model="studentEvent.checkin[prop]"
                                          :disabled="!state.editing"
                                          :reduce="(option) => option.value"
                                          label="label"
                                          :options="options.yesNo"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :select-on-tab="true"
                                          placeholder="Not Selected"
                                          :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']"
                                          @input="update(studentEvent)"/> <!-- @input="updateStudentEventCheckin(studentEvent)"-->
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </vue-perfect-scrollbar>
              </template>
            </b-card-actions>
          </template>
          <template v-else>
            <div class="ps-room-assignments">
              <b-list-group class="rounded p-1 bg-light-primary"/>
            </div>
          </template>
        </template>
        <template v-else>
          <vue-perfect-scrollbar :settings="scrollbarSettings" class="ps-room-assignments">
            <b-list-group class="rounded p-1 bg-light-primary">
              <b-list-group-item v-for="(studentEvent, index) in selectedChaperoneStudentEvents"
                                 :key="studentEvent.id"

                                 tag="li"
                                 :action="false"
                                 :class="['rounded border-0 shadow p-2', index === 0 ? 'mt-0' : 'mt-1']">
                <b-row>
                  <b-col cols="auto" align-self="start">
                    <slot name="avatar" :student="studentEvent.student">
                      <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(studentEvent.student)">
                        <font-awesome-icon :icon="getSexIcon(studentEvent.student)" style="width: 22px; height: 22px" />
                        <template #badge>
                          {{ getSexBadge(studentEvent.student) }}
                        </template>
                      </b-avatar>
                    </slot>
                  </b-col>
                  <b-col align-self="center">
                    <slot name="item" :studentEvent="studentEvent">
                      <div class="truncate">
                        <h5 class="mb-25 font-weight-bolder">
                          {{ studentEvent.student.name.first }} {{ studentEvent.student.name.last }}
                        </h5>
                        <div :data="application = getApplicationWithSelection(studentEvent.student.applications)" class="font-small-3">
                          {{ application.selection.ensemble.name }} - {{ application.instrument.name }}
                        </div>
                        <div class="font-small-3">
                          {{ getHotelName(studentEvent.hotelId) }} - Room {{ studentEvent.room }}
                        </div>
                      </div>
                    </slot>
                  </b-col>
                  <b-col v-for="(_, prop) in studentEvent.checkin" :key="prop" class="col-4 col-xxxl-20 col-qhd-2">
                    <b-row>
                      <b-col align-self="center" cols="auto">
                        <b-avatar :variant="getCheckinVariant(studentEvent.checkin[prop])" size="44px">
                          <font-awesome-icon :icon="['far', getCheckinIcon(studentEvent.checkin[prop])]" style="width: 22px; height: 22px"/>
                        </b-avatar>
                      </b-col>
                      <b-col align-self="center">
                        <b-form-group :label="prop" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold text-capitalize">
                          <v-select v-model="studentEvent.checkin[prop]"
                                    :disabled="!state.editing"
                                    :reduce="(option) => option.value"
                                    label="label"
                                    :options="options.yesNo"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :select-on-tab="true"
                                    placeholder="Not Selected"
                                    :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']"
                                    @input="update(studentEvent)"/> <!-- @input="updateStudentEventCheckin(studentEvent)"-->
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </template>
      </b-card>
    </template>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import events from '@/mixins/event.mixin';
import notify from '@/mixins/notify.mixin';
import Fuse from 'fuse.js';
import SortInput from '@/components/SortInput.vue';
import HotelRoom from '@/views/events/hotels/HotelRoom.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import _ from 'lodash';
import {yesNo} from '@/views/events/service/data';

export default {
  name: 'BedCheckinTab',
  components: {
    BCardActions,
    HotelRoom,
    SortInput,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ events, notify ],
  props: {
    selected: {
      type: Object,
      required: true
    },
    hotels: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    sorting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: null,
      studentEvents: {
        items: [],
        loading: false
      },
      filters: {
        hotel: null,
        instrument: null,
        ensemble: null
      },
      grouped: {
        by: null,
        options: [
          {
            label: 'Student',
            valueKey: 'student.id',
            textKey: 'student.name',
            textFn: (name) => {
              if (!name || !name.first || !name.last) return '';
              return `${name.first} ${name.last}`;
            }
          },
          { label: 'Room', valueKey: 'room'},
          {
            label: 'Hotel',
            valueKey: 'hotelId',
            textKey: 'hotelId',
            textFn: (hotelId) => this.hotels.items.find(hotel => hotel.id === hotelId)?.name ?? ''
          },
          { label: 'Bus', valueKey: 'busId'},
          {
            label: 'Instrument',
            valueKey: 'student.applications.items[0].instrument.id',
            textKey: 'student.applications.items[0].instrument.name'
          },
          {
            label: 'Ensemble',
            valueKey: 'student.applications.items[0].selection.ensemble.id',
            textKey: 'student.applications.items[0].selection.ensemble.name'
          }
        ]
      },
      ui: {
        filters: {
          show: false,
          expanded: false
        },
        sorting: {
          show: false,
          expanded: false
        },
        grouped: {
          show: false,
          expanded: false
        }
      },
      options: {
        yesNo: yesNo
      },
    }
  },
  computed: {
    selectedChaperoneStudentEvents() {
      const { items } = this.studentEvents
      const fuse = new Fuse(items, {
        useExtendedSearch: true,
        keys: [
          'id',
          'student.id',
          'student.name.first',
          'student.name.last',
          'student.applications.items.instrument.id',
          'student.applications.items.selection.ensemble.id',
          'hotelId'
        ]
      })

      const query = { $and: [ ] }
      if(this.filters.hotel) { query.$and.push({ hotelId: this.filters.hotel }) }
      if(this.filters.instrument) { query.$and.push({ 'student.applications.items.instrument.id': this.filters.instrument }) }
      if(this.filters.ensemble) { query.$and.push({ 'student.applications.items.selection.ensemble.id': this.filters.ensemble }) }

      if(this.search) {
        query.$and.push({
          $or: [
            { id: `'${this.search}` },
          ]
        })
      }

      let searchedItems;
      if(query.$and.length) { searchedItems = fuse.search(query).map(({ item }) => item) }
      else { searchedItems = items }
      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, searchedItems);
      }
      return searchedItems;
    },

    groupedItems() {
      const items = this.selectedChaperoneStudentEvents;
      if (!items.length) return [];

      const { valueKey, textKey, textFn } = this.grouped.by;
      const grouped = Object.groupBy(items, (item) => _.get(item, valueKey));

      return Object.entries(grouped).map(([key, values]) => {
        const value = values.find(v => _.get(v, textKey) !== undefined);
        const text = _.get(value, textKey) || key;

        return {
          text: textFn !== undefined ? textFn(text) : text,
          key: key,
          items: values
        };
      });
    },

    instruments() {
      if(!this.studentEvents.items.length) return []
      const instruments = this.studentEvents.items.map(i => {
        const application = this.getApplicationWithSelection(i.student.applications);
        return application?.instrument;
      })
      return instruments
          .filter((instrument, index) => instrument && instruments.findIndex(i => i.id === instrument.id) === index)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    ensembles() {
      if(!this.studentEvents.items.length) return []
      const ensembles = this.studentEvents.items.map(i => {
        const application = this.getApplicationWithSelection(i.student.applications);
        return application?.selection?.ensemble;
      })
      return ensembles
          .filter((ensemble, index) => ensemble && ensembles.findIndex(i => i.id === ensemble.id) === index)
          .sort((a, b) => a.name.localeCompare(b.name));
    },
    isFiltered() {
      return this.search || (this.filters && Object.values(this.filters).some(f => f !== null));
    },
    activeFilters() {
      return Object.values(this.filters).filter(f => f !== null);
    },
  },
  mounted() {
    this.studentEvents.items = this.selected.chaperone.studentEvents.items;
  },
  methods: {
    update(studentEvent) {
      const event = this.studentEvents.items.find(item => item.id === studentEvent.id)
      if(event) {
        event.checkin = studentEvent.checkin
        this.$emit('update', event)
      }
    },
    getHotelName(id) {
      return this.hotels.items.find(item => item.id === id)?.name ?? null
    },
  }
}
</script>

<style scoped lang="scss">
.ps-room-assignments {
  max-height: 60vh;

  .list-group-item:hover {
    background-color: var(--white);
    cursor: default;
  }

  .list-group:empty:before,
  .list-group > div:empty:before {
    content: 'No Students Associated With This Chaperone...';
  }
}

.ps--active-y:hover {
  padding-right: 1rem;
  transition: 0.25s;
}

.ps__rail-y:hover>.ps__thumb-y {
  width: 6px;
}
</style>
