/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Selection may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Applications currently associated with this Selection will have their association removed.<br/>
            This may effect selections, acceptance, invoices, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Selection was successfully deleted.' }
}

export async function cascadeDeleteSelection(selectionId, swalCallback) {
    try {
        /** Update Application record associated to this Selection **/
        await updateApplication(selectionId, swalCallback)

        /** Delete Selection **/
        await deleteSelection(selectionId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteSelection(selectionId, swalCallback) {
    const title = 'Deleting Selection'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteSelectionMutation, { input: { id: selectionId }}));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function updateApplication(selectionId, swalCallback) {
    swalCallback(new SwalData('Loading Application', 0, 1))

    /** Get the Selection record in order to get the Application ID **/
    const response = await API.graphql(graphqlOperation(getSelectionQuery, { id: selectionId } ));
    const selection = response.data.getSelection;

    /** If the Selection has an Application ID, we need to update the Application record, removing the Selection reference **/
    if(selection && selection.selectionApplicationId) {
        swalCallback(new SwalData('Loading Application', 1, 1))
        await new Promise(resolve => setTimeout(resolve, 1000));
        swalCallback(new SwalData('Updating Application', 0, 1))
        await API.graphql(graphqlOperation(updateApplicationMutation, { input: { id: selection.selectionApplicationId, applicationSelectionId: null }} ));
        await new Promise(resolve => setTimeout(resolve, 1000));
        swalCallback(new SwalData('Updating Application', 1, 1))
    }
}


/** Queries & Mutations **/
const getSelectionQuery = /* GraphQL */ `
    query GetSelection($id: ID!) {
        getSelection(id: $id) {
            id
            selectionApplicationId
        }
    }
`;

const updateApplicationMutation = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            applicationSelectionId
        }
    }
`;

const deleteSelectionMutation = /* GraphQL */ `
    mutation DeleteSelection(
        $input: DeleteSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        deleteSelection(input: $input, condition: $condition) {
            id
        }
    }
`;
