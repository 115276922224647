export const rules = {
    address: {
        city: {
            required: true,
            alpha_spaces: true,
            max: 48
        },
        state: {
            required: true,
            alpha: true,
            min: 2,
            max: 2
        },
        zip: {
            required: true,
            regex: /^\d{5}(?:[-\s]\d{4})?$/
        }
    },
    school: {
        beds: {
            required: true,
            numeric: true,
            min: 6,
            max: 12
        }
    }
}

export const other = {

}
