export const recommendations = [
    'I recommend Highly',
    'I recommend',
    'I recommend with Reservation',
    'I do not recommend'
];

export const gradings = ['Superior', 'Good', 'Fair' ];

export const grades = ['9', '10', '11', '12'];
