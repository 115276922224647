<template>
  <b-form-input :id="id"
                :value="value"
                :formatter="formatter" lazy-formatter
                :disabled="disabled"
                :state="validationState"
                @input="$emit('input', $event)"/>
</template>

<script>
import Fuse from 'fuse.js';

export default {
  name: 'StateInput',
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationState: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      states: [
        { abbreviation: 'AK', name: 'Alaska'},
        { abbreviation: 'TX', name: 'Texas'},
        { abbreviation: 'AL', name: 'Alabama'},
        { abbreviation: 'AR', name: 'Arkansas'},
        { abbreviation: 'AZ', name: 'Arizona'},
        { abbreviation: 'CA', name: 'California'},
        { abbreviation: 'CO', name: 'Colorado'},
        { abbreviation: 'CT', name: 'Connecticut'},
        { abbreviation: 'DC', name: 'District of Columbia'},
        { abbreviation: 'DE', name: 'Delaware'},
        { abbreviation: 'FL', name: 'Florida'},
        { abbreviation: 'GA', name: 'Georgia'},
        { abbreviation: 'HI', name: 'Hawaii'},
        { abbreviation: 'IA', name: 'Iowa'},
        { abbreviation: 'ID', name: 'Idaho'},
        { abbreviation: 'IL', name: 'Illinois'},
        { abbreviation: 'IN', name: 'Indiana'},
        { abbreviation: 'KS', name: 'Kansas'},
        { abbreviation: 'KY', name: 'Kentucky'},
        { abbreviation: 'LA', name: 'Louisiana'},
        { abbreviation: 'MA', name: 'Massachusetts'},
        { abbreviation: 'MD', name: 'Maryland'},
        { abbreviation: 'ME', name: 'Maine'},
        { abbreviation: 'MI', name: 'Michigan'},
        { abbreviation: 'MN', name: 'Minnesota'},
        { abbreviation: 'MO', name: 'Missouri'},
        { abbreviation: 'MS', name: 'Mississippi'},
        { abbreviation: 'MT', name: 'Montana'},
        { abbreviation: 'NC', name: 'NorthCarolina'},
        { abbreviation: 'ND', name: 'NorthDakota'},
        { abbreviation: 'NE', name: 'Nebraska'},
        { abbreviation: 'NH', name: 'NewHampshire'},
        { abbreviation: 'NJ', name: 'NewJersey'},
        { abbreviation: 'NM', name: 'NewMexico'},
        { abbreviation: 'NV', name: 'Nevada'},
        { abbreviation: 'NY', name: 'NewYork'},
        { abbreviation: 'OH', name: 'Ohio'},
        { abbreviation: 'OK', name: 'Oklahoma'},
        { abbreviation: 'OR', name: 'Oregon'},
        { abbreviation: 'PA', name: 'Pennsylvania'},
        { abbreviation: 'RI', name: 'RhodeIsland'},
        { abbreviation: 'SC', name: 'SouthCarolina'},
        { abbreviation: 'SD', name: 'SouthDakota'},
        { abbreviation: 'TN', name: 'Tennessee'},
        { abbreviation: 'TX', name: 'Texas'},
        { abbreviation: 'UT', name: 'Utah'},
        { abbreviation: 'VA', name: 'Virginia'},
        { abbreviation: 'VT', name: 'Vermont'},
        { abbreviation: 'WA', name: 'Washington'},
        { abbreviation: 'WI', name: 'Wisconsin'},
        { abbreviation: 'WV', name: 'WestVirginia'},
        { abbreviation: 'WY', name: 'Wyoming'}
      ],
    }
  },
  methods: {
    formatter(value) {
      const fuse = new Fuse(this.states, {
        keys: ['abbreviation', 'name'],
        shouldSort: true,
        threshold: 0.5,
        //includeScore: true
      })

      if(value.length) {
        const result = fuse.search(value, { limit: 1 })
        if(result.length) {
          this.$emit('input', result[0].item.abbreviation)
          return result[0].item.abbreviation
        }
      }
      return value
    }
  }
}
</script>

<style scoped>

</style>
