var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_vm._t("alerts")],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.showSidebar)?_c('b-col',{class:[
               'd-print-none',
                'col-12 col-md-4 col-xl-4 col-xxl-4 col-xxxl-25 col-xxxxl-20'
           ]},[_vm._t("sidebar")],2):_vm._e()],1),_c('b-col',{class:[
      _vm.showSidebarRight
      ? 'col-12 col-md-8 col-xl-8 col-xxl-8 col-xxxl-auto col-xxxxl-auto'
      : 'col-12 col-md-8 col-xl-8 col-xxl-8 col-xxxl-75 col-xxxxl-80'
  ]},[(_vm.hasId)?_vm._t("content"):_vm._t("empty",function(){return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('b-img',{staticClass:"d-block mx-auto img",attrs:{"src":_vm.emptyImage,"alt":"Bus","fluid":""}}),_c('div',{staticClass:"display-4"},[_vm._v("Nothing Selected")]),_c('div',[_vm._v(_vm._s(_vm.word))])],1)]})],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.showSidebarRight)?_c('b-col',{class:[
        'd-print-none pt-2 pt-xxxl-0',
        'col-12 col-md-4 col-xl-4 col-xxl col-xxxl col-xxxxl-20'
    ]},[_vm._t("sidebar-right")],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }