<template>
  <b-card no-body class="border shadow-sm">
    <b-card-header class="d-block">
      <b-row>
        <b-col>
          <label :for="`experienceYesNo-${labelKey}`" class="mb-0">
            {{ labelText }}
          </label>
        </b-col>
        <b-col cols="auto">
          <validation-provider #default="{ errors }" name="Experience" rules="">
            <b-form-checkbox :id="`experienceYesNo-${labelKey}`"
                             v-model="question.value" switch
                             :state="errors.length > 0 ? false:null"
                             :disabled="!editable"
                             button-variant="primary"
                             @change="syncQuestion">
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
              <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
            </b-form-checkbox>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-header>
  </b-card>
</template>

<script>
export default {
  name: 'ExperienceYesNo',
  props: {
    value: {
      type: Boolean,
      default: null
    },
    labelText: {
      type: String,
      default: '',
      required: false
    },
    labelKey: {
      type: String,
      default: '',
      required: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      question: {
        value: this.value
      },
    }
  },
  methods: {
    syncQuestion() {
      this.$emit('input', this.question.value)
    },
  }
}
</script>

<style scoped>

</style>
