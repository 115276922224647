var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"tag":"form"}},[(_vm.showHeading)?_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Application Details")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter Application Details.")])])],1):_vm._e(),(!_vm.state.loading)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"align-self":"center"}},[_c('validation-provider',{attrs:{"name":"Festival","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Festival","label-for":"festival","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"input-id":"festival","options":_vm.festivals,"label":"name","loading":_vm.options.festivals.loading,"filter":_vm.festivalsFilter,"reduce":function (f) { return ({ id: f.id, name: f.name }); },"selectable":function (option) { return option.state.enabled === true; },"select-on-tab":true,"append-to-body":false,"disabled":!_vm.editable,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.emitFestival},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var site = ref.site;
var zone = ref.zone;
var state = ref.state;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v("Festival "+_vm._s(name))]),(zone)?_c('small',[_vm._v(_vm._s(zone.name)+" "+_vm._s(site ? (" | " + site) : null))]):_c('small',{staticClass:"text-danger"},[_vm._v("No Zone "+_vm._s(site ? (" | " + site) : null))])]),(!state.enabled)?_c('span',{attrs:{"title":"Disabled"}},[_c('b-icon-lock-fill')],1):_vm._e()])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var site = ref.site;
return [_vm._v(" Festival "+_vm._s(name)+" "+_vm._s(site ? (" - " + site) : null)+" ")]}}],null,true),model:{value:(_vm.application.festival),callback:function ($$v) {_vm.$set(_vm.application, "festival", $$v)},expression:"application.festival"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2626335846)})],1),(_vm.userHasSchoolZone || _vm.tourProps.festivalsBtn.show)?_c('b-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('b-form-group',{attrs:{"id":"festivals-btn","label":"Showing","label-for":"festival-cb"}},[_c('b-checkbox',{attrs:{"id":"festival-cb","disabled":!_vm.editable,"button":"","button-variant":"outline-primary"},model:{value:(_vm.options.festivals.showAll),callback:function ($$v) {_vm.$set(_vm.options.festivals, "showAll", $$v)},expression:"options.festivals.showAll"}},[_vm._v(" "+_vm._s(_vm.options.festivals.showAll ? 'All Festivals' : 'My Festivals')+" ")])],1)],1):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Form","label-for":"form","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"input-id":"form","options":_vm.forms,"label":"name","loading":_vm.options.forms.loading,"reduce":function (f) { return ({ id: f.id, name: f.name, type: f.type }); },"selectable":function (option) { return option.state.enabled === true; },"select-on-tab":true,"append-to-body":false,"disabled":!_vm.editable,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"option:selecting":function($event){_vm.application.instrument = null},"input":_vm.emitForm},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var state = ref.state;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(_vm._s(name))]),(!state.enabled)?_c('span',{attrs:{"title":"Disabled"}},[_c('b-icon-lock-fill')],1):_vm._e()])]}}],null,true),model:{value:(_vm.application.form),callback:function ($$v) {_vm.$set(_vm.application, "form", $$v)},expression:"application.form"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1289558495)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Instrument","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Instrument","label-for":"instrument","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"input-id":"instrument","options":_vm.instruments,"label":"name","loading":_vm.options.forms.loading,"reduce":function (i) { return ({ id: i.id, name: i.name }); },"selectable":function (option) { return option.state.enabled === true; },"select-on-tab":true,"append-to-body":false,"disabled":!_vm.editable || _vm.application.form === null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.emitInstrument},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var state = ref.state;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',[_vm._v(_vm._s(name))]),(!state.enabled)?_c('span',{attrs:{"title":"Disabled"}},[_c('b-icon-lock-fill')],1):_vm._e()])]}}],null,true),model:{value:(_vm.application.instrument),callback:function ($$v) {_vm.$set(_vm.application, "instrument", $$v)},expression:"application.instrument"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3049717095)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }