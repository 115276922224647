<template>
  <validation-observer ref="observer" tag="form">
    <b-row>
      <b-col v-if="showHeading" cols="12" class="mb-2">
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="mb-0">
              Grading
            </h5>
            <small class="text-muted">Describe the students skill / understanding in the following categories.</small>
          </div>
          <div class="d-block">
            <b-dropdown id="grading-dropdown"
                        size="sm"
                        variant="light"
                        toggle-class="text-decoration-none btn-icon d-block"
                        no-caret
                        :right="$store.state.appConfig.isRTL" dropleft
                        :disabled="!editable"
                        :class="{ show: tourProps.options.show }"
                        :popper-opts="{ positionFixed: true }">
              <template #button-content>
                <b-icon icon="star-fill" scale=".75"/><span class="sr-only">Quick Grading Selection</span>
              </template>
<!--              <b-dropdown-header id="grading-dropdown-header">Grading:</b-dropdown-header>-->
              <b-dropdown-group id="grading-dropdown-group" header="Grading">
                <b-dropdown-item v-for="(grading, index) in options.gradings" :id="`grading-dropdown-item-${index}`" :key="grading" @click="setGrading(grading)">
                  {{ grading }}
                </b-dropdown-item>
              </b-dropdown-group>
            </b-dropdown>
          </div>
        </div>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Tone" rules="required">
          <b-form-group label="Tone" label-for="tone" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.tone"
                      input-id="tone"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Articulation" rules="required">
          <b-form-group label="Articulation" label-for="articulation" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.articulation"
                      input-id="articulation"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Flexibility" rules="required">
          <b-form-group label="Flexibility" label-for="flexibility" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.flexibility"
                      input-id="flexibility"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Technique" rules="required">
          <b-form-group label="Technique" label-for="technique" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.technique"
                      input-id="technique"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Rhythmic Sense" rules="required">
          <b-form-group label="Rhythmic Sense" label-for="rhythmicSense" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.rhythmicSense"
                      input-id="rhythmicSense"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Festival" rules="required">
          <b-form-group label="Intonation" label-for="intonation" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.intonation"
                      input-id="intonation"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="General Musicianship" rules="required">
          <b-form-group label="General Musicianship" label-for="generalMusicianship" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.generalMusicianship"
                      input-id="generalMusicianship"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Sight Reading" rules="required">
          <b-form-group label="Sight Reading" label-for="sightReading" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.sightReading"
                      input-id="sightReading"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Leadership" rules="required">
          <b-form-group label="Leadership" label-for="leadership" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.leadership"
                      input-id="leadership"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Citizenship" rules="required">
          <b-form-group label="Citizenship" label-for="citizenship" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.citizenship"
                      input-id="citizenship"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Attitude" rules="required">
          <b-form-group label="Attitude" label-for="attitude" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.attitude"
                      input-id="attitude"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Responsibility" rules="required">
          <b-form-group label="Responsibility" label-for="responsibility" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.responsibility"
                      input-id="responsibility"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider #default="{ errors }" name="Maturity" rules="required">
          <b-form-group label="Maturity" label-for="maturity" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.maturity"
                      input-id="maturity"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col v-if="showDiction" md="3">
        <validation-provider #default="{ errors }" name="Diction" rules="required">
          <b-form-group label="Diction" label-for="diction" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.grading.diction"
                      input-id="diction"
                      :options="options.gradings"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncGrading"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="Recommendation" rules="required">
          <b-form-group label="Recommendation" label-for="recommendation" :state="errors.length > 0 ? false:null">
            <v-select v-model="application.recommendation"
                      input-id="recommendation"
                      :options="options.recommendations"
                      :select-on-tab="true"
                      :disabled="!editable"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      @input="syncRecommendation"/>
            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">
        <b-form-group label="Comments" label-for="comment" description="Comments which support the recommendations">
          <validation-provider #default="{ errors }" name="Comments" rules="required">
            <b-form-textarea id="comment"
                            v-model="application.comments"
                            :state="errors.length > 0 ? false:null"
                            :rows="4"
                            :disabled="!editable"
                            :placeholder="`${ computedStudentName } is a hard working student that works well with others, and performs very well.`"
                            :debounce="250"
                            @update="syncComments"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { gradings, recommendations } from '@/data/application'

export default {
  name: 'ApplicationGrading',
  components: {
    vSelect
  },
  props: {
    form: {
      type: Object,
      default: null
    },
    student: {
      type: Object,
      default: null
    },
    grading: {
      type: Object,
      default: null
    },
    recommendation: {
      type: String,
      default: null
    },
    comments: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    showHeading: {
        type: Boolean,
        default: true
    },
    tourProps: {
      type: Object,
      default: () => ({
        options: {
          show: false
        },
      })
    }
  },
  data() {
    return {
      application: {
        grading: {
          tone: this.grading?.tone || null,
          rhythmicSense: this.grading?.rhythmicSense || null,
          leadership: this.grading?.leadership || null,
          maturity: this.grading?.maturity || null,
          articulation: this.grading?.intonation || null,
          intonation: this.grading?.tone || null,
          citizenship: this.grading?.citizenship || null,
          diction: this.grading?.diction || null,
          flexibility: this.grading?.flexibility || null,
          generalMusicianship: this.grading?.generalMusicianship || null,
          attitude: this.grading?.attitude || null,
          technique: this.grading?.technique || null,
          sightReading: this.grading?.sightReading || null,
          responsibility: this.grading?.responsibility || null
        },
        recommendation: this.recommendation,
        comments: this.comments,
      },
      options: {
        gradings: gradings,
        recommendations: recommendations
      }
    }
  },
  computed: {
    computedStudentName() {
      return this.student?.name?.first && this.student?.name?.last ? `${this.student.name.first} ${this.student.name.last}` : '{ Student Name }'
    },
    showDiction() {
      return this.form?.name?.toLowerCase().includes('voice') || this.form?.name?.toLowerCase().includes('vocal');
    }
  },
  methods: {
    setGrading(grading) {
      Object.keys(this.application.grading).forEach(key => {
        this.application.grading[key] = grading
      })

      if(!this.showDiction) {
        this.application.grading.diction = null
      }
      this.syncGrading()
    },
    syncGrading() {
      this.$emit('update:grading', this.application.grading)
    },
    syncRecommendation() {
      this.$emit('update:recommendation', this.application.recommendation)
    },
    syncComments() {
      this.$emit('update:comments', this.application.comments)
    },
  }
}
</script>

<style scoped>

</style>
