<template>
  <div>
<!--    {{ v-bind="$attrs" }}-->
    <b-form-input :id="id"
                  v-mask="['#####', '#####-####']"
                  :value="value"
                  :disabled="disabled"
                  :state="validationState"
                  @input="$emit('input', $event)"/>
  </div>

</template>

<script>
import Fuse from 'fuse.js';
/*import {VueMaskDirective} from 'v-mask';*/
import { mask } from 'vue-the-mask'

export default {
  name: 'ZipInput',
  /*directives: { mask: VueMaskDirective },*/
  directives: { mask },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationState: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      
    }
  },
  methods: {
    /*mask(value) {
      const zip = [/\d/, /\d/, /\d/, /\d/, /\d/];
      const plusFour = [/\d/, /\d/, /\d/, /\d/];
      return value?.length > 5 ? [...zip, '-', ...plusFour] : zip;
    },*/
  }
}
</script>

<style scoped>

</style>
