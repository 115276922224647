<template>
  <b-card :class="['border']" body-class="px-1">
    <b-card-title class="d-flex justify-content-between" >
      Room {{ room.number }}
      <b-icon-info-circle v-if="room.occupants.some(occupant => occupant.type === 'student')" @click="sidebar.visible = !sidebar.visible" />
    </b-card-title>

    <template v-if="sidebar.visible">
      <b-sidebar v-model="sidebar.visible" lazy width="35vw" bg-variant="white" right backdrop no-header-close>
        <template #title>
          <h5 class="mt-1">Room {{ room.number }}</h5>
        </template>
        <div class="px-2 py-2">
          <!-- Students -->
          <b-card-actions v-if="students.length" title="Students" :collapsed="false" action-collapse card-class="sidebar-student-card-wrapper border-primary" bg-variant="light-primary">
            <template v-if="students.length">
              <b-card-actions v-for="(student) in students" :key="student.id"
                              :data="application = getApplicationWithSelection(student.applications)"
                              collapsed action-collapse
                              card-class="border shadow sidebar-student-card" header-class="pt-1" no-body>
                <template #title>
                  <h6 class="mb-0">{{ getFullName(student) }}</h6>
                </template>
                <b-row class="mb-1">
                  <b-col>
                    <b-list-group flush>
                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <small>School</small>
                        <b-badge variant="light-primary" pill>{{ student.school.name.legal }}</b-badge>
                      </b-list-group-item>

                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <small>Grade</small>
                        <b-badge variant="light-primary" pill>{{ student.grade }}</b-badge>
                      </b-list-group-item>

                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <small>Sex</small>
                        <b-badge variant="light-primary" pill>{{ student.sex }}</b-badge>
                      </b-list-group-item>

                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <small>Gender</small>
                        <b-badge variant="light-primary" pill>{{ student.gender }}</b-badge>
                      </b-list-group-item>

                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <small>Ensemble</small>
                        <b-badge variant="light-primary" pill>{{ application.selection.ensemble.name }}</b-badge>
                      </b-list-group-item>

                      <b-list-group-item class="d-flex justify-content-between align-items-center">
                        <small>Instrument</small>
                        <b-badge variant="light-primary" pill>{{ application.instrument.name }}</b-badge>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-card-actions>
            </template>
            <template v-else>
              <b-card-text>No students have been assigned to this room.</b-card-text>
            </template>
          </b-card-actions>

          <!-- Matches -->
          <b-card-actions v-if="students.length > 1" title="Match Details" action-collapse card-class="border-primary" bg-variant="light-primary">
            <template v-if="students.length >= 3">

              <!-- Match Search -->
              <b-input v-if="matches.search.show" v-model="matches.search.value" type="search" placeholder="Search" class="mb-2"/>

              <!-- Match Filters -->
              <b-input-group v-if="matches.filters.show"  class="mb-2">
                <b-input-group-prepend is-text><b-icon-funnel/></b-input-group-prepend>
                <v-select v-model="matches.filters.value" :options="students" :reduce="option => option.id" label="name" placeholder="Student Name">
                  <template #option="{ name }">
                    {{ name.first }} {{ name.last }}
                  </template>
                  <template #selected-option="{ name }">
                    {{ name.first }} {{ name.last }}
                  </template>
                </v-select>
              </b-input-group>
            </template>

            <b-row>
              <b-col v-for="(match, index) in roomMatches.matches" :key="index" :class="roomMatches.classes"> <!--:cols="roomMatches.cols"-->
                <b-card class="border mb-50" no-body>
                  <template #header>
                    <div class="d-flex w-100 justify-content-between align-items-center font-small-3 font-weight-bold">
                      <div :class="getMatchOrderClass(match.student.id)">
                        {{ match.student.name.first }} {{ match.student.name.last }}
                      </div>
                      <b-icon-arrow-left-right :class="getMatchOrderClass()"/>
                      <div :class="getMatchOrderClass(match.matched.id)">
                        {{ match.matched.name.first }} {{ match.matched.name.last }}
                      </div>
                    </div>
                  </template>
                  <b-list-group flush>
                    <b-list-group-item v-for="constraint in match.constraintScores" :key="constraint.key" class="d-flex justify-content-between align-items-center">
                      <small>{{ constraint.label }}</small>
                      <b-badge :variant="constraint.score > 0 ? 'light-success' : 'light-danger'" pill>{{ constraint.score }}</b-badge>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center mt-2">
                      <small class="font-weight-bold">Overall Score</small>
                      <b-badge :variant="match.score > 0 ? 'light-success' : 'light-danger'" pill>{{ match.score }}</b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>
          </b-card-actions>

          <!-- Chaperones -->
          <b-card-actions v-if="chaperones.length" title="Chaperones" action-collapse card-class="border-primary" bg-variant="light-primary">
            <b-card-actions v-for="(chaperone) in chaperones" :key="chaperone" collapsed action-collapse
                            card-class="border shadow" header-class="pt-1" no-body>
              <template #title>
                <h6 class="mb-0">{{ chaperone.name.first }} {{ chaperone.name.last }}</h6>
              </template>
            </b-card-actions>
          </b-card-actions>
        </div>
      </b-sidebar>
    </template>


    <b-list-group flush class="students draggable-list-group rounded">
      <b-list-group-item v-for="(occupant) in room.occupants"
                         :key="occupant.id"
                         :to="getRoute(occupant)"
                         class="p-1"
                         @click="$event.stopPropagation()">
        <b-row>
          <b-col align-self="start" cols="auto" class="pr-25">
            <b-avatar :variant="`light-primary`" size="44px" :badge-variant="getSexBadgeVariant(occupant.raw)">
              <font-awesome-icon :icon="getIcon(occupant)" style="width: 22px; height: 22px" />
              <template #badge v-if="occupant.type === 'student'" >
                {{ getSexBadge(occupant.raw) }}
              </template>
            </b-avatar>
          </b-col>
          <b-col align-self="center">
            <div class="font-weight-bold">{{ occupant.name.first }} {{ occupant.name.last }}</div>
            <slot name="text" :type="occupant.type" :occupant="occupant.raw" />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import draggable from 'vuedraggable';
import events from '@/mixins/event.mixin'
import _ from 'lodash';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Fuse from 'fuse.js';
import vSelect from 'vue-select';

export default {
  name: 'HousingRoom',
  components: {
    BCardActions,
    draggable,
    vSelect
  },
  mixins: [events],
  props: {
    room: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      sidebar: { visible: false },
      icon: 'fas fa-graduation-cap',
      matches: {
        search: {
          show: false,
          value: null
        },
        filters: {
          show: true,
          value: null
        }
      }
    }
  },
  computed: {
    students() {
      return this.room.occupants?.filter(occupant => occupant.type === 'student')?.map(occupant => occupant.raw) ?? []
    },
    chaperones() {
      //return this.students?.map(student => student.event?.chaperoneId)
      return this.room.occupants?.filter(occupant => occupant.type === 'chaperone')?.map(occupant => occupant.raw) ?? []
    },
    roomMatches() {
      if (this.students.length) {
        const list = []
        this.students.forEach(student => {
          const matches = this.findMatches(student, this.students.filter(s => s.id !== student.id))
          matches.forEach(match => {
            if(list.filter(item => item.ids.includes(student.id) && item.ids.includes(match.student.id)).length === 0) {
              list.push({ ids: [student.id, match.student.id], student: student, match: match})
            }
          })
        })

        let classes = ''
        if(list.length <= 1) { classes = 'col-12' }
        else if(list.length === 2) { classes = 'col-12 col-xxxl-6' }
        else { classes = 'col-12 col-xxxl-6' }

        const matches = list.map(item => ({
          student: item.student,
          matched: item.match.student,
          score: item.match.score,
          constraintScores:
          item.match.constraintScores
        }))

        let items = matches
        const searchValue = this.matches.search.value
        const filterValue = this.matches.filters.value


        if(searchValue || filterValue) {
          const keys = [
            'student.id',
            'student.name.first',
            'student.name.last',
            'matched.id',
            'matched.name.first',
            'matched.name.last',
          ]

          const fuse = new Fuse(matches, {
            useExtendedSearch: true,
            keys: keys
          })

          const query = { $and: [ ] }
          if(filterValue) {
            const or = [
              {'student.id': `=${filterValue}` },
              {'matched.id': `=${filterValue}` }
            ]
            query.$and.push({ $or: or })
          }

          if(searchValue) {
            const or = []
            keys.forEach(key => {
              or.push({[key.name]: `'${searchValue}` })
            })
            query.$and.push({ $or: or }) //Push our $or clauses into the $and clause
          }
          //Search Using Our Query
          items = fuse.search(query).map(({ item }) => item)
        }

        return {
          classes: classes,
          matches: items.sort((a, b) => (
              list.length > 3
                  ? a.student.name.first.localeCompare(b.student.name.first) && a.student.name.last.localeCompare(b.student.name.last)
                  : b.score - a.score))
        }
      }
      return { classes: '', matches: [] }
    }
  },
  methods: {
    getIcon(occupant) {
      switch (occupant.type) {
        case 'student':
          return this.getSexIcon(occupant.raw);
        case 'chaperone':
          return 'fas fa-people-roof';
        default:
          return 'fas fa-user';
      }
    },
    getRoute(occupant) {
      return {
        name: occupant.type === 'chaperone' ? 'events-chaperone' : 'events-student',
        params: {
          id: occupant.id
        }
      }
    },
    findMatches(student, items) {
      //https://stackoverflow.com/questions/43867145/javascript-filter-object-array-and-return-top-to-best-match-array
      const scored = items.map((item) => {
        let score = 0
        const constraintScores = []
        const constraints = this.defaultConstraints()

        constraints.forEach((constraint) => {
          if (constraint.fn(_.get(student, constraint.key), _.get(item, constraint.key))) {
            score = parseFloat((score + constraint.weight).toFixed(2))
            constraintScores.push({
              key: constraint.key,
              label: constraint.label,
              score: constraint.weight
            })
          }
          else {
            score = parseFloat((score - constraint.weight).toFixed(2));
            constraintScores.push({
              key: constraint.key,
              label: constraint.label,
              score: -constraint.weight
            })
          }
        });
        return {
          score: score,
          student: item,
          constraintScores: constraintScores
        };
      });
      return scored.sort((a, b) => b.score - a.score);
    },
    getMatchOrderClass(studentId = null) {
      // Apply no specific order class if filters.value is not set
      if (!this.matches.filters.value) {
        return '';
      }
      if (!studentId) {
        return 'order-2'; // Should only happen if trying to set an icon
      }
      return this.matches.filters.value === studentId ? 'order-1' : 'order-3';
    },
    defaultConstraints() {
      return [
        {
          key: 'sex',
          weight: 1,
          fn: (p, f) => p === f,
          label: 'Sex',
          min: 0,
          max: 1,
          step: 0.1
        },
        {
          key: 'ensemble.id',
          weight: 0.7,
          fn: (p, f) => p === f,
          label: 'Ensemble',
          min: 0,
          max: 1,
          step: 0.1
        },
        {
          key: 'instrument.id',
          weight: 0.3,
          fn: (p, f) => p === f,
          label: 'Instrument',
          min: 0,
          max: 1,
          step: 0.1
        },
        {
          key: 'school.id',
          weight: 0.1,
          fn: (p, f) => p === f,
          label: 'School',
          min: 0,
          max: 1,
          step: 0.1
        },
        {
          key: 'grade',
          weight: 0.1,
          fn: (p, f) => p === f,
          label: 'Grade',
          min: 0,
          max: 1,
          step: 0.1
        },

      ]
    },
  }
}



</script>

<style scoped>
.list-group:empty,
.list-group > div:empty {
  padding:1rem;
  margin-right: .5rem;
  text-align:left;
  border: 1px dashed rgba(34, 41, 47, 0.125);
}

.draggable-list-group .list-group-item:hover {
  background-color: #fff;
  //background-color: inherit;
}

.students.list-group:empty:before,
.students.list-group > div:empty:before {
  content: 'No Students Selected...';
}

.chaperones.list-group:empty:before,
.chaperones.list-group > div:empty:before {
  content: 'No Chaperones Selected...';
}

.card ::v-deep .b-overlay-wrap:last-child > .sidebar-student-card:last-child {
  margin-bottom: 0.5rem !important;
}
</style>
