<template>
  <b-card no-body class="border shadow-sm">
    <b-card-header class="d-block">
      <b-row>
        <b-col>
          <label for="moreThanOneConference" class="mb-0">
            Is the student auditioning for more than one Conference All-State Organization?
          </label>
        </b-col>
        <b-col cols="auto">
          <validation-provider #default="{ errors }" vid="response" name="More Than One Conference" :rules="rules.response">
            <b-form-checkbox id="moreThanOneConference"
                             v-model="question.moreThanOne.response" switch
                             :state="errors.length > 0 ? false:null"
                             :disabled="!editable"
                             button-variant="primary"
                             @change="syncQuestion">
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body v-if="question.moreThanOne.response">
      <validation-provider v-slot="{ errors, validate }" vid="instruments" name="Instrument" :rules="rules.instruments">
        <b-form-group label="Please select all instruments options / voice part for which the student is auditioning." label-for="instrument" :state="errors.length > 0 ? false:null">
          <v-select ref="moreThanOneInstruments"
                    v-model="question.moreThanOne.instruments"
                    input-id="instrument"
                    multiple label="name"
                    :reduce="instrument => instrument.id"
                    :options="instruments"
                    :select-on-tab="true"
                    :disabled="!editable"
                    :loading="options.instruments.loading"
                    :selectable="(option) => isSelectableOption(option)"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    @input="syncQuestion" @search:blur="validate"/>

          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import {API, graphqlOperation} from 'aws-amplify';
import {listInstruments} from './moreThanOneConference';
import { extend } from 'vee-validate';

export default {
  name: 'MoreThanOneConference',
  components: {
    vSelect
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    formType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      question: {
        moreThanOne: {
          response: this.value?.response || false,
          instruments: this.value?.instruments || []
        },
      },
      options: {
        instruments: {
          items: [],
          loading: false,
          loaded: false
        }
      },
      rules: {
        response: {
          required: false
        },
        instruments: {
          //required_if: 'response',
          min_length: { min: 1 }
        }
      }
    }
  },
  computed: {
    instruments() {
      if (!this.options.instruments.loaded) {
        this.listInstruments()
        return []
      }
      if(this.formType === 'voice') {
        return this.options.instruments.items.filter(item => item.form.type !== 'voice').sort((a, b) => a.name.localeCompare(b.name))
      }
      if(this.formType === 'jazzVocal') {
        return this.options.instruments.items.filter(item => item.form.type !== 'jazzVocal').sort((a, b) => a.name.localeCompare(b.name))
      }
      return this.options.instruments.items
    }
  },
  watch: {
    formType(n, o) {
      if(n !== o) {
        this.question.moreThanOne.instruments = []
      }
    },
    'question.moreThanOne.response'(value) {
      this.$nextTick(() => {
        if(value) {
          this.$refs.moreThanOneInstruments.$refs.search.focus()
        }
      })
    }
  },
  async created() {
    //await this.listInstruments()
  },
  mounted() {
    extend('min_length', {
      computesRequired: true,
      params: ['min'],
      validate: (value, { min } = {}) => value.length >= min,
      message: (data, required) => `The ${data} field requires at least ${required.min} instrument.`
    });
  },
  methods: {
    async listInstruments() {
      try {
        this.options.instruments.loading = true
        const response = await API.graphql(graphqlOperation(listInstruments));
        this.options.instruments.items = response.data.listInstruments.items.sort((a, b) => a.name.localeCompare(b.name))
      }
      catch(error) {
        console.error(error)
      }
      finally {
        this.options.instruments.loaded = true
        this.options.instruments.loading = false
      }
    },
    isSelectableOption(option) {
      const selectedOptions = this.options.instruments.items.filter(item => this.question.moreThanOne.instruments.includes(item.id))
      if(option.form.type === 'voice' && selectedOptions.some(selectedOption => selectedOption.form.type === 'voice')) {
        return false
      }
      if(option.form.type === 'jazzVocal' && selectedOptions.some(selectedOption => selectedOption.form.type === 'jazzVocal')) {
        return false
      }
      return !this.question.moreThanOne.instruments.includes(option.id)
    },
    syncQuestion() {
      this.$emit('input', this.question.moreThanOne)
    },
  }
}
</script>

<style scoped>

</style>
