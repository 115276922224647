<template>
  <b-card no-body class="border shadow-sm">
    <b-card-header class="d-block">
      <b-row>
        <b-col>
          <span class="app-label"> {{ labelText }}</span>
          <i v-if="editable" class="font-small-2 text-danger">If this application includes more than one organization, add each organization in it's own text field.</i>
        </b-col>
        <b-col v-if="editable" cols="auto">
          <b-button id="organization-add-btn" v-b-tooltip="'Add Organization'" size="sm" variant="primary" :disabled="!editable" @click="addOrganization">
            <feather-icon icon="PlusIcon"/>
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <template v-if="question.organizations.length">
        <b-row v-for="(organization, index) in question.organizations" :key="index">
          <b-col align-self="center">
            <b-form-group>
              <validation-provider v-slot="{ errors, validate }" :vid="`org-${type}-${index}`" name="Organization" rules="required|min:2">
                <b-form-input :id="`org-${type}-${index}`"
                              :ref="`org-${type}-${index}`"
                              v-model="question.organizations[index]"
                              :state="errors.length > 0 ? false:null"
                              :disabled="!editable"
                              @input="syncQuestion" @blur="validate"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="editable" cols="auto" align-self="start">
            <b-button v-b-tooltip="'Remove Organization'" size="sm" variant="light" :disabled="!editable" @click="removeOrganization(index)">
              <feather-icon icon="TrashIcon"/></b-button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-alert show variant="dark"><small>{{ emptyText }}</small></b-alert>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'Organizations',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    emptyText: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      question: {
        organizations: this.value || []
      },
    }
  },
  methods: {
    addOrganization() {
      const length = this.question.organizations.push('')
      this.$nextTick(() => {
        const ref = `org-${this.type}-${length - 1}`
        this.$refs[ref][0].focus()
      })
      this.syncQuestion()
    },
    removeOrganization(index) {
      this.question.organizations.splice(index, 1)
      this.syncQuestion()
    },
    syncQuestion() {
      this.$emit('input', this.question.organizations)
    },
  }

}
</script>

<style scoped>

</style>
