// eslint-disable-next-line import/prefer-default-export
export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                form {
                    type
                }
            }
            nextToken
        }
    }
`;
