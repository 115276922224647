<template>
  <b-card no-body class="border shadow-sm">
    <b-card-header class="d-block">
      <b-row>
        <b-col>
          <label for="lastYearAttended" class="mb-0">
            Was the student a member of last year's Conference All-State?
          </label>
        </b-col>
        <b-col cols="auto">
          <validation-provider #default="{ errors }" name="Attended" rules="">
            <b-form-checkbox id="lastYearAttended"
                             v-model="question.lastYear.attended" switch
                             :state="errors.length > 0 ? false:null"
                             :disabled="!editable"
                             button-variant="primary"
                             @change="syncQuestion">

                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
            </b-form-checkbox>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body v-if="question.lastYear.attended">
      <b-row>
        <b-col v-if="showGroup">
          <validation-provider #default="{ errors }" name="Group" rules="">
            <b-form-group label="Which Group?" label-for="group" :state="errors.length > 0 ? false:null">
              <v-select v-model="question.lastYear.group"
                        input-id="group"
                        label="name"
                        :reduce="group => group.name"
                        :options="options.groups"
                        :select-on-tab="true"
                        :disabled="!editable"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="syncQuestion"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col v-if="showPart">
          <label for="part">Which Part?</label>
          <validation-provider #default="{ errors }" name="Part" rules="">
            <b-input id="part"
                     v-model="question.lastYear.part"
                     :state="errors.length > 0 ? false:null"
                     :disabled="!editable"
                     @input="syncQuestion"/>
          </validation-provider>
        </b-col>
        <b-col v-if="showChair">
          <label for="chair">Which Chair?</label>
          <validation-provider #default="{ errors }" name="Chair" rules="">
            <b-input id="chair"
                     v-model="question.lastYear.chair"
                     :state="errors.length > 0 ? false:null"
                     :disabled="!editable"
                     @input="syncQuestion"
            />
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'LastYearConference',
  components: {
    vSelect
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    showGroup: {
      type: Boolean,
      default: false
    },
    showPart: {
      type: Boolean,
      default: false
    },
    showChair: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      question: {
        lastYear: {
          attended: this.value?.attended || false,
          group: this.value?.group || null,
          part: this.value?.part || null,
          chair: this.value?.chair || null
        },
      },
      options: {
        groups: [
          { id: 1, name: 'Instrumental Jazz', hasChair: false },
          { id: 2, name: 'Mixed Chorus', hasChair: false },
        /*  { id: 3, name: 'National', hasChair: false },
          { id: 4, name: 'National Alt', hasChair: false },*/
          { id: 5, name: 'String Orchestra', hasChair: true },
          { id: 6, name: 'Symphonic Band', hasChair: true },
          { id: 7, name: 'Symphony Orchestra', hasChair: true },
          { id: 8, name: 'Treble Choir', hasChair: false },
          { id: 9, name: 'Vocal Jazz', hasChair: false },
          { id: 10, name: 'Wind Ensemble', hasChair: true },
          { id: 11, name: 'Women\'s Chorus', hasChair: false },
          { id: 999, name: 'Other', hasChair: false },
        ]
      }
    }
  },
  computed: {

  },
  methods: {
    syncQuestion() {
      this.$emit('input', this.question.lastYear)
    },
  }
}
</script>

<style scoped>

</style>
