/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {cascadeDeleteApplication} from '@/graphql/cascade/application';
import {cascadeDeleteInvoice} from '@/graphql/cascade/invoice';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Chaperone may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Students associated with this Chaperone will be modified.<br/>
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Chaperone was successfully deleted.' }
}

export async function cascadeDeleteChaperone(chaperoneId, swalCallback) {
    try {
        /** Update StudentEvent records associated to this Chaperone **/
        const studentEvents = await listStudentEventsByChaperone(chaperoneId, swalCallback)
        await batchProcessWithRetry(studentEvents, async (studentEvent) => {
            await API.graphql(graphqlOperation(updateStudentEventMutation, { input: { id: studentEvent.id, chaperoneId: null }}));
        }, { title: 'Updating Student Events', callback: swalCallback })


        /** Delete Chaperone **/
        await deleteChaperone(chaperoneId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteChaperone(chaperoneId, swalCallback) {
    const title = 'Deleting Chaperone'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteChaperoneMutation, { input: { id: chaperoneId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listStudentEventsByChaperone(chaperoneId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { chaperoneId: chaperoneId, limit: 250, nextToken: nextToken }
            const response = await API.graphql(graphqlOperation(listStudentEventsByChaperoneQuery, input));
            items.push(...response.data.listStudentEventsByChaperone.items)
            nextToken = response.data.listStudentEventsByChaperone.nextToken;
            swalCallback(new SwalData('Loading Student Events', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const deleteChaperoneMutation = /* GraphQL */ `
    mutation DeleteChaperone(
        $input: DeleteChaperoneInput!
        $condition: ModelChaperoneConditionInput
    ) {
        deleteChaperone(input: $input, condition: $condition) {
            id
        }
    }
`;

const listStudentEventsByChaperoneQuery = /* GraphQL */ `
    query ListStudentEventsByChaperone(
        $chaperoneId: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentEventFilterInput
        $limit: Int
        $nextToken: String
        #        $includeChaperone: Boolean = false
    ) {
        listStudentEventsByChaperone(
            chaperoneId: $chaperoneId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;
const updateStudentEventMutation = /* GraphQL */ `
    mutation UpdateStudentEvent(
        $input: UpdateStudentEventInput!
        $condition: ModelStudentEventConditionInput
    ) {
        updateStudentEvent(input: $input, condition: $condition) {
            id
        }
    }
`;


