export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            schools {
                items {
                    school {
                        id
                        name {
                            legal
                            popular
                        }
                        zone {
                            id
                            name
                        }
                        district {
                            id
                            districtZoneId
                        }
                        districtSchoolsId
                        schoolZoneId
                    }
                }
            }
        }
    }
`;

export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;
export const onUpdateSetting = /* GraphQL */ `
    subscription OnUpdateSetting {
        onUpdateSetting {
            key
            value
        }
    }
`;

export const getApplication = /* GraphQL */ `
    query GetApplication($id: ID!) {
        getApplication(id: $id) {
            id
            festival {
                id
                name
            }
            form {
                id
                name
                type
            }
            instrument {
                id
                name
            }
            student {
                id
                name {
                    first
                    last
                }
                dob
                sex
                grade
                school {
                    id
                    name {
                        legal
                        popular
                    }
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
            }
            teacher {
                id
                username
                name {
                    first
                    last
                }
                schools {
                    items {
                        school {
                            id
                            beds
                            districtSchoolsId
                            schoolZoneId
                        }
                    }
                }
            }
            questions {
                lastYear {
                    attended
                    group
                    part
                    chair
                }
                moreThanOne {
                    response
                    instruments
                }
                organizations {
                    school
                    other
                }
                experience {
                    instrument {
                        years
                        doubles
                    }
                    vocalJazzAccompaniment
                    jazzEnsemble
                    orchestral
                }
                previousSoloRating {
                    ninth {
                        grade
                        level
                    }
                    tenth {
                        grade
                        level
                    }
                }
            }
            grading {
                tone
                rhythmicSense
                leadership
                maturity
                articulation
                intonation
                citizenship
                diction
                flexibility
                generalMusicianship
                attitude
                technique
                sightReading
                responsibility
            }
            recommendation
            comments
            teacherID
            createdAt
            updatedAt
        }
    }
`;
export const createApplication = /* GraphQL */ `
    mutation CreateApplication(
        $input: CreateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        createApplication(input: $input, condition: $condition) {
            id
            festival {
                id
                slug
                name
                site
                zone {
                    id
                    name
                    counties
                    createdAt
                    updatedAt
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                festivalZoneId
            }
            form {
                id
                slug
                name
                instruments {
                    nextToken
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            instrument {
                id
                slug
                name
                form {
                    id
                    slug
                    name
                    createdAt
                    updatedAt
                }
                state {
                    enabled
                }
                createdAt
                updatedAt
                formInstrumentsId
            }
            student {
                id
                name {
                    first
                    last
                }
                dob
                sex
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                grade
                applications {
                    nextToken
                }
                createdAt
                updatedAt
            }
            teacher {
                id
                username
                name {
                    first
                    last
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                schools {
                    items {
                        school {
                            id
                            beds
                            slug
                            createdAt
                            updatedAt
                            districtSchoolsId
                            schoolZoneId
                        }
                    }
                }
                avatar {
                    size
                    name
                    src
                }
                createdAt
                updatedAt
                districtUsersId
            }
            questions {
                lastYear {
                    attended
                    group
                    part
                    chair
                }
                moreThanOne {
                    response
                    instruments
                }
                organizations {
                    school
                    other
                }
                experience {
                    vocalJazzAccompaniment
                    jazzEnsemble
                    orchestral
                }
            }
            grading {
                tone
                rhythmicSense
                leadership
                maturity
                articulation
                intonation
                citizenship
                diction
                flexibility
                generalMusicianship
                attitude
                technique
                sightReading
                responsibility
            }
            recommendation
            comments
            ranking {
                score
                local
                state
            }
            createdAt
            updatedAt
            studentApplicationsId
            applicationFestivalId
            applicationFormId
            applicationInstrumentId
            teacherID
        }
    }
`;
export const updateApplication = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
        }
    }
`;

export const listFestivals = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                site
                zone {
                    id
                    name
                }
                state {
                    enabled
                }
                festivalZoneId
            }
        }
    }
`;
export const onUpdateFestival = /* GraphQL */ `
    subscription OnUpdateFestival {
        onUpdateFestival {
            id
            name
            site
            zone {
                id
                name
            }
            state {
                enabled
            }
            festivalZoneId
            
        }
    }
`;

export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                type
                instruments {
                    items {
                        id
                        name
                        state {
                            enabled
                        }
                    }
                }
                state {
                    enabled
                }
            }
        }
    }
`;
export const onUpdateForm = /* GraphQL */ `
    subscription OnUpdateForm {
        onUpdateForm {
            id
            name
            type
            instruments {
                items {
                    id
                    name
                    state {
                        enabled
                    }
                }
            }
            state {
                enabled
            }
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
                formInstrumentsId
            }
            nextToken
        }
    }
`;
export const onUpdateInstrument = /* GraphQL */ `
    subscription OnUpdateInstrument {
        onUpdateInstrument {
            id
            name
            state {
                enabled
            }
            formInstrumentsId
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
            }
            nextToken
        }
    }
`;

/** Students **/

export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    first
                    last
                }
                dob
                sex
                grade
                school {
                    id
                    name {
                        legal
                        popular
                    }
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                applications {
                    items {
                        teacherID
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listStudentsBySchool = /* GraphQL */ `
    query ListStudentsBySchool(
        $schoolID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listStudentsBySchool(
            schoolID: $schoolID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name {
                    first
                    last
                }
                dob
                sex
                grade
                school {
                    id
                    name {
                        legal
                        popular
                    }
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
                applications {
                    items {
                        teacherID
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const createStudent = /* GraphQL */ `
    mutation CreateStudent(
        $input: CreateStudentInput!
        $condition: ModelStudentConditionInput
    ) {
        createStudent(input: $input, condition: $condition) {
            id
            name {
                first
                last
            }
            dob
            sex
            grade
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            phone {
                type
                number
                ext
            }
            email {
                type
                address
            }
            applications {
                items {
                    id
                    recommendation
                    comments
                    createdAt
                    updatedAt
                    studentApplicationsId
                    applicationFestivalId
                    applicationFormId
                    applicationInstrumentId
                    teacherID
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const listApplicationsByTeacher = /* GraphQL */ `
    query ListApplicationsByTeacher(
        $teacherID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listApplicationsByTeacher(
            teacherID: $teacherID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    dob
                    sex
                    grade
                    school {
                        id
                        name {
                            legal
                            popular
                        }
                    }
                    address {
                        line1
                        line2
                        city
                        county
                        state
                        zip
                    }
                    phone {
                        type
                        number
                        ext
                    }
                    email {
                        type
                        address
                    }
                    applications {
                        items {
                            teacherID
                        }
                    }
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
