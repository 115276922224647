import { render, staticRenderFns } from "./HotelRoom.vue?vue&type=template&id=60e92bd9&scoped=true&"
import script from "./HotelRoom.vue?vue&type=script&lang=js&"
export * from "./HotelRoom.vue?vue&type=script&lang=js&"
import style0 from "./HotelRoom.vue?vue&type=style&index=0&id=60e92bd9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e92bd9",
  null
  
)

export default component.exports