<template>
  <validation-observer ref="observer" tag="form">
    <b-row v-if="showHeading">
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Questions</h5>
        <small class="text-muted">Answer Questions About Student's Experience.</small>
      </b-col>
    </b-row>

    <template v-if="isForm('voice')">
      <!-- Question 1 -->
      <last-year-conference
          v-model="application.questions.lastYear"
          show-group show-part
          :editable="editable" />

      <!-- Question 2 -->
      <more-than-one-conference
          v-model="application.questions.moreThanOne"
          :editable="editable"
          form-type="voice"/>

      <!-- Question 3 -->
      <organizations
          v-model="application.questions.organizations.school" type="school"
          :label-text="options.organizations.school.label"
          :empty-text="options.organizations.school.empty"
          :editable="editable" />

      <!-- Question 4 -->
      <organizations
          v-model="application.questions.organizations.other" type="other"
          :label-text="options.organizations.other.label"
          :empty-text="options.organizations.other.empty"
          :editable="editable" />

      <!-- Question 5 -->
      <previous-solo-ratings
          v-model="application.questions.previousSoloRating"
          :editable="editable" />
    </template>

    <template v-else-if="isForm('strings')">
      <!-- Question 1 -->
      <last-year-conference
          v-model="application.questions.lastYear"
          show-group show-chair show-part
          :editable="editable" />

      <!-- Question 2 -->
      <more-than-one-conference
          v-model="application.questions.moreThanOne"
          :editable="editable"
          form-type="strings"/>

      <!-- Question 3 -->
      <organizations
          v-model="application.questions.organizations.school" type="school"
          :label-text="options.organizations.school.label"
          :empty-text="options.organizations.school.empty"
          :editable="editable" />

      <!-- Question 4 -->
      <organizations
          v-model="application.questions.organizations.other" type="other"
          :label-text="options.organizations.other.label"
          :empty-text="options.organizations.other.empty"
          :editable="editable" />

      <!-- Question 5 -->
      <previous-solo-ratings
          v-model="application.questions.previousSoloRating"
          :editable="editable" />
    </template>

    <template v-else-if="isForm('windAndPercussion')">
      <!-- Question 1 -->
      <last-year-conference
          v-model="application.questions.lastYear"
          show-group show-part show-chair
          :editable="editable" />

      <!-- Question 2 -->
      <more-than-one-conference
          v-model="application.questions.moreThanOne"
          :editable="editable"
          form-type="windAndPercussion"/>

      <!-- Question 3 -->
      <organizations
          v-model="application.questions.organizations.school" type="school"
          :label-text="options.organizations.school.label"
          :empty-text="options.organizations.school.empty"
          :editable="editable" />

      <!-- Question 4 -->
      <organizations
          v-model="application.questions.organizations.other" type="other"
          :label-text="options.organizations.other.label"
          :empty-text="options.organizations.other.empty"
          :editable="editable" />


      <!-- Question 5 -->
<!--      <experience-yes-no
          v-model="application.questions.experience.jazzEnsemble"
          label-key="jazzEnsemble"
          :label-text="options.jazzEnsemble.label"
          :editable="editable" />-->

      <experience-yes-no
          v-model="application.questions.experience.orchestral"
          label-key="orchestral"
          :label-text="options.orchestral.label"
          :editable="editable" />

      <!-- Question 6 -->
      <previous-solo-ratings
          v-model="application.questions.previousSoloRating"
          :editable="editable" />
    </template>

    <template v-else-if="isForm('jazzInstrumental')">
      <!-- Question 1 -->
      <experience-instrument
          v-model="application.questions.experience.instrument"
          :editable="editable" />

      <!-- Question 2 -->
      <experience-yes-no
          v-model="application.questions.experience.vocalJazzAccompaniment"
          label-key="vocalJazzAccompaniment"
          :label-text="options.vocalJazzAccompaniment.label"
          :editable="editable" />

      <!-- Question 3 -->
      <last-year-conference
          v-model="application.questions.lastYear"
          show-group show-part
          :editable="editable" />

      <!-- Question 4 -->
      <more-than-one-conference
          v-model="application.questions.moreThanOne"
          :editable="editable"
          form-type="jazzInstrumental"/>

      <!-- Question 5 -->
      <organizations
          v-model="application.questions.organizations.school" type="school"
          :label-text="options.organizations.school.label"
          :empty-text="options.organizations.school.empty"
          :editable="editable" />

      <!-- Question 6 -->
      <organizations
          v-model="application.questions.organizations.other" type="other"
          :label-text="options.organizations.other.label"
          :empty-text="options.organizations.other.empty"
          :editable="editable" />

      <!-- Question 7 -->
      <previous-solo-ratings
          v-model="application.questions.previousSoloRating"
          :editable="editable" />
    </template>

    <template v-else-if="isForm('jazzVocal')">
      <!-- Question 1 -->
      <experience-instrument
          v-model="application.questions.experience.instrument"
          :editable="editable" />

      <!-- Question 2 -->
<!--      <experience-yes-no
          v-model="application.questions.experience.vocalJazzAccompaniment"
          label-key="vocalJazzAccompaniment"
          :label-text="options.vocalJazzAccompaniment.label"
          :editable="editable" />-->

      <!-- Question 3 -->
      <last-year-conference
          v-model="application.questions.lastYear"
          show-group show-part
          :editable="editable" />

      <!-- Question 4 -->
      <more-than-one-conference
          v-model="application.questions.moreThanOne"
          :editable="editable"
          form-type="jazzVocal"/>

      <!-- Question 5 -->
      <organizations
          v-model="application.questions.organizations.school" type="school"
          :label-text="options.organizations.school.label"
          :empty-text="options.organizations.school.empty"
          :editable="editable" />

      <!-- Question 6 -->
      <organizations
          v-model="application.questions.organizations.other" type="other"
          :label-text="options.organizations.other.label"
          :empty-text="options.organizations.other.empty"
          :editable="editable" />

      <!-- Question 7 -->
      <previous-solo-ratings
          v-model="application.questions.previousSoloRating"
          :editable="editable" />
    </template>

    <template v-else>
      <b-alert show variant="danger">
        <strong>Unable to determine the Form type!</strong><br/>
        <small>Unfortunately, we'll have to show you all of the available questions.</small>
      </b-alert>

      <!-- Question 3 -->
      <last-year-conference
          v-model="application.questions.lastYear"
          show-group show-part show-chair
          :editable="editable" />

      <!-- Question 4 -->
      <more-than-one-conference
          v-model="application.questions.moreThanOne"
          :editable="editable"
          form-type="other"/>

      <!-- Question 5 -->
      <organizations
          v-model="application.questions.organizations.school" type="school"
          :label-text="options.organizations.school.label"
          :empty-text="options.organizations.school.empty"
          :editable="editable" />

      <!-- Question 6 -->
      <organizations
          v-model="application.questions.organizations.other" type="other"
          :label-text="options.organizations.other.label"
          :empty-text="options.organizations.other.empty"
          :editable="editable" />

      <!-- Question 1 -->
      <experience-instrument
          v-model="application.questions.experience.instrument"
          :editable="editable" />

      <!-- Question 2 -->
      <experience-yes-no
          v-model="application.questions.experience.vocalJazzAccompaniment"
          label-key="vocalJazzAccompaniment"
          :label-text="options.vocalJazzAccompaniment.label"
          :editable="editable" />

      <experience-yes-no
          v-model="application.questions.experience.jazzEnsemble"
          label-key="jazzEnsemble"
          :label-text="options.jazzEnsemble.label"
          :editable="editable" />

      <experience-yes-no
          v-model="application.questions.experience.orchestral"
          label-key="orchestral"
          :label-text="options.orchestral.label"
          :editable="editable" />

      <!-- Question 7 -->
      <previous-solo-ratings
          v-model="application.questions.previousSoloRating"
          :editable="editable" />
    </template>
  </validation-observer>
</template>

<script>
import LastYearConference from '@/views/all-state/application/questions/LastYearConference.vue';
import MoreThanOneConference from '@/views/all-state/application/questions/MoreThanOneConference.vue';
import Organizations from '@/views/all-state/application/questions/Organizations.vue';
import PreviousSoloRatings from '@/views/all-state/application/questions/PreviousSoloRatings.vue';
import ExperienceYesNo from '@/views/all-state/application/questions/ExperienceYesNo.vue';
import ExperienceInstrument from '@/views/all-state/application/questions/ExperienceInstrument.vue';

export default {
  name: 'ApplicationQuestions',
  components: {
    ExperienceInstrument,
    ExperienceYesNo,
    PreviousSoloRatings,
    Organizations,
    MoreThanOneConference,
    LastYearConference,
  },
  props: {
    form: {
      type: Object,
      default: null
    },
    questions: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    },
    showHeading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      application: {
        questions: {
          lastYear: {
            attended: this.questions?.lastYear?.attended || false,
            group: this.questions?.lastYear?.group || null,
            part: this.questions?.lastYear?.part || null,
            chair: this.questions?.lastYear?.chair || null
          },
          moreThanOne: {
            response: this.questions?.moreThanOne?.response || false,
            instruments: this.questions?.moreThanOne?.instruments || []
          },
          organizations: {
            school: this.questions?.organizations?.school || [],
            other: this.questions?.organizations?.other || []
          },
          experience: {
            instrument: {
              years: this.questions?.experience?.instrument?.years || null,
              doubles: this.questions?.experience?.instrument?.doubles || null
            },
            vocalJazzAccompaniment: this.questions?.experience?.vocalJazzAccompaniment || false,
            jazzEnsemble: this.questions?.experience?.jazzEnsemble || false,
            orchestral: this.questions?.experience?.orchestral || false
          },
          previousSoloRating: {
            ninth: {
              level: this.questions?.previousSoloRating?.ninth?.level || null,
              grade: this.questions?.previousSoloRating?.ninth?.grade || null
            },
            tenth: {
              level: this.questions?.previousSoloRating?.tenth?.level || null,
              grade: this.questions?.previousSoloRating?.tenth?.grade || null
            }
          }
        },
      },
      options: {
        organizations: {
          school: {
            label: 'List the names of corresponding local school organizations in which the student is a participating member.',
            empty: 'No local school organizations listed'
          },
          other: {
            label: 'List the names of non-school music organizations in which the student performs regularly and musical honors.',
            empty: 'No non-school music organizations listed'
          }
        },
        vocalJazzAccompaniment: {
          label: 'Does the student have Vocal Jazz Accompaniment experience (drum set or guitar only)?'
        },
        jazzEnsemble: {
          label: 'Does the student have Jazz Ensemble experience?'
        },
        orchestral: {
          label: 'Does the student have Orchestral experience?'
        },
      },
    }
  },
  watch: {
    application: {
      deep: true,
      handler() {
        this.syncQuestions()
      }
    }
  },
  methods: {
    isForm(type) {
      if(this.form) {
        return this.form.type === type
      }
      return false
    },
    syncQuestions() {
      this.$emit('update:questions', this.application.questions)
    },
  }
}
</script>

<style scoped>
  form .card:last-child {
    margin-bottom: 0;
  }
</style>
