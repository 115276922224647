export default {
    data() {
        return {
            settingsStore: {
                app: null
            }
        }
    },
    mounted() {
        this.settingsStore.app = this.$store.state.settings.app
    },
    watch: {
        '$store.state.settings': {
            handler: function (value, oldValue) {
                this.settingsStore.app = value.app;
            },
            deep: true
        }
    },
    methods: {
        isCreatedAtInCurrentYear(createdAt) {
            const start = new Date(this.settingsStore.app.current.year.start);
            const end = new Date(this.settingsStore.app.current.year.end);
            const createdAtDate = new Date(createdAt);
            return createdAtDate >= start && createdAtDate <= end;
        }
    }
}
