<template>
  <page-layout ref="layout" :show-print-option="false" class="d-print-none" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Events - ${year}`" />
      <template v-if="id">
        <b-breadcrumb-item text="Chaperones" :to="{ name: 'events-chaperones', params: { id: null } }" />
        <b-breadcrumb-item v-if="selected.chaperone" :text="getFullName(selected.chaperone)" active />
      </template>
      <template v-else>
        <b-breadcrumb-item text="Chaperones" active />
      </template>
    </template>

    <template v-if="hasChanges" #actions="{ }">
      <b-button v-b-tooltip="'Revert Changes'" variant="link" size="sm" class="btn-icon mr-50" @click="resetSelectedChaperone">
        <b-icon-arrow-counterclockwise />
      </b-button>
      <can do="update" on="events-chaperone">
        <b-button v-b-tooltip="'Update'" variant="link" size="sm" class="btn-icon" @click="updateChaperone">
          <b-icon-save2 />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="events-chaperones">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
      </can>
      <can do="delete" on="events-chaperones">
        <b-dropdown-item class="table-row-option-delete"
                         @click="$refs.layout.confirmDelete(selected.chaperone, deleteChaperone, cascadeConfirmDeleteOptions)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="sidebar = !sidebar">
        <feather-icon icon="SidebarIcon"/>
        <span class="align-middle ml-50">{{ sidebar ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: chaperones.loading, desc: 'Loading Chaperones'},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #modal>

    </template>

    <template #content="{ state }">
      <event-layout :show-sidebar="sidebar" :auto-toggle-sidebar="false">
        <template #sidebar>
          <events-nav title="Chaperones"
                      :selected-id="id"
                      :items="computedChaperones"
                      :filters="chaperones.filters"
                      :sorting="chaperones.sorting"
                      @search="(text) => chaperones.search = text"
                      @sort="(sorting) => chaperones.sorting = sorting"
                      @select="(item) => selectChaperone(item)"
                      @deselect="deselectChaperone">

            <template #item="{ item, emptyTextClass }">
              <span v-if="item.name && (item.name.first || item.name.last)">{{ getFullName(item) }}</span>
              <span v-else :class="emptyTextClass">Unnamed Chaperone</span>
            </template>

            <template #actions>
              <can do="create" on="events-chaperones">
                <b-button size="xs" variant="link" class="btn-icon round px-50" @click="addChaperone">
                  <b-icon-plus />
                </b-button>
              </can>
            </template>

            <template #filters>
              <v-select
                  v-model="chaperones.filters.hotelId"
                  input-id="filter-hotel"
                  placeholder="Hotel"
                  :options="hotels.items" label="name"
                  :reduce="option => option.id"
                  :select-on-tab="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="w-100 font-small-3 mt-2">
                <template #option="{ name }">
                  <span :class="{'text-danger font-small-3': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
                <template #selected-option="{ name }">
                  <span :class="{'text-danger': !name}">
                    {{ name ? name : 'Unnamed Hotel' }}
                  </span>
                </template>
              </v-select>
            </template>
          </events-nav>

          <!-- Print Card -->
          <b-card class="chaperones-checkin-card mb-2" body-class="pt-0" :img-src="image" img-alt="Bus" img-top tag="article">
            <b-card-title class="mb-0"> Bed Checkins</b-card-title>
            <b-card-text class="mt-50">
              <p class="mb-50">To print all bed checkin sheets, click the button below.</p>
              <p class="font-small-3 mb-50">To configure custom sorting, click <u><b-link class="font-weight-bold" @click="studentEvents.sorting.show = !studentEvents.sorting.show">here.</b-link></u></p>

              <sort-input v-if="studentEvents.sorting.show" v-model="studentEvents.sorting" class="my-1"/>

              <span class="font-small-3">For individual bed checkin sheets, please select a chaperone from the list and click the printer icon to the right of the 'Bed Checks' tab.</span>
            </b-card-text>




            <b-button block variant="primary" @click="openModal('chaperones-checkins')">Print Bed Checkins</b-button>
            <bed-checkin-modal id="chaperones-checkins" :chaperones="chaperones.items" :sorting="studentEvents.sorting"/>
          </b-card>
        </template>
        <template #content>
          <template v-if="selected.chaperone">
            <validation-observer ref="observer" tag="div">
              <form ref="form">
                <b-row class="match-height">
                  <b-col class="col-12">
                    <b-card>
                      <b-row>
                        <!-- Avatar -->
                        <b-col align-self="start" cols="auto">
                          <b-avatar variant="primary" size="45px" badge-variant="white">
                            <font-awesome-icon :icon="icon" size="2x"></font-awesome-icon>
                          </b-avatar>
                        </b-col>
                        <!-- Details -->
                        <b-col align-self="center">
                          <!-- Details - Name -->
                          <b-row>
                            <b-col align-self="start" class="col-12 col-xxl-4">
                              <validation-provider v-slot="validationContext" vid="legal-name" name="Prefix" rules="required">
                                <b-form-group label="Prefix" label-for="prefix-input" label-class="font-weight-bold" :invalid-feedback="validationContext.errors[0]">
                                  <v-select id="prefix-input"
                                            v-model="selected.chaperone.name.prefix"
                                            :disabled="!state.editing"
                                            :options="options.prefixes"
                                            :loading="hotels.loading"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :select-on-tab="true"
                                            :state="getValidationState(validationContext)"
                                            placeholder="Not Selected"
                                            :class="['w-100']"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" class="col-6 col-xxl-4">
                              <validation-provider v-slot="validationContext" vid="legal-name" name="Legal Name" rules="required">
                                <b-form-group label="First Name" label-for="legal-name-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input v-model="selected.chaperone.name.first" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" class="col-6 col-xxl-4">
                              <validation-provider v-slot="validationContext" vid="popular-name" name="Popular Name" rules="required">
                                <b-form-group label="Last Name" label-for="popular-name-input" :invalid-feedback="validationContext.errors[0]">
                                  <b-input v-model="selected.chaperone.name.last" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col align-self="start" cols="12">
                              <validation-provider v-slot="validationContext" vid="role" name="Role" rules="required">
                                <b-form-group label="Role" label-for="role-input" class="m-0 p-0" label-class="font-weight-bold" :invalid-feedback="validationContext.errors[0]">
                                  <v-select id="role-input"
                                            v-model="selected.chaperone.role"
                                            :disabled="!state.editing"
                                            :options="options.roles"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :select-on-tab="true"
                                            :state="getValidationState(validationContext)"
                                            placeholder="Not Selected"
                                            :class="['w-100']"/>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>

                  <b-col v-if="hasChanges" cols="12">
                    <b-alert show variant="primary" class="d-print-none mb-2" dismissible>
                      <b-icon-info-circle-fill font-scale="1"/> <strong class="align-middle ml-50 font-small-3">Unsaved Changes</strong>
                      <p class="align-middle font-small-3">
                        Make sure you save before switching to another Chaperone. Unsaved changes will be lost.
                      </p>
                    </b-alert>
                  </b-col>

                  <!-- Event Info -->
                  <b-col cols="12" class="col-xxl-4">
                    <!-- Hotel -->
                    <b-card no-body>
                      <b-card-body>
                        <b-row>
                          <b-col align-self="center" cols="auto">
                            <b-avatar :variant="`light-primary`" size="45">
                              <font-awesome-icon icon="fa-solid fa-hotel" size="2x" />
                            </b-avatar>
                          </b-col>
                          <b-col align-self="center">
                            <b-form-group label="Hotel" label-for="hotel-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                              <v-select id="hotel-input"
                                        v-model="selected.chaperone.hotelId"
                                        label="name"
                                        :disabled="!state.editing"
                                        :options="hotels.items"
                                        :reduce="(option) => option.id"
                                        :loading="hotels.loading"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :select-on-tab="true"
                                        placeholder="Not Selected"
                                        :class="['w-100', !state.editing ? 'vs--plain-text' : 'vs--plain-text']"/>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <b-col cols="12" class="col-xxl-4">
                    <!-- Room -->
                    <b-card no-body>
                      <b-card-body>
                        <b-row>
                          <b-col align-self="center" cols="auto">
                            <b-avatar :variant="`light-primary`" size="45">
                              <font-awesome-icon icon="fa-solid fa-bed" size="2x" />
                            </b-avatar>
                          </b-col>
                          <b-col align-self="center">
                            <b-form-group label="Room" label-for="room-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                              <b-form-input
                                  id="room-input"
                                  v-model="selected.chaperone.room"
                                  type="number"
                                  :disabled="!state.editing"
                                  placeholder="Not Selected"
                                  :clearable="true"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :class="['w-100', !state.editing ? 'form-control-plaintext' : 'form-control-plaintext']"/>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </b-col>
                  <b-col cols="12" class="col-xxl-4">
                    <!-- Phone -->
                    <b-card no-body>
                      <b-card-body>
                        <b-row>
                          <b-col align-self="center" cols="auto">
                            <b-avatar :variant="`light-primary`" size="45">
                              <font-awesome-icon icon="fa-solid fa-phone" size="2x" />
                            </b-avatar>
                          </b-col>
                          <b-col align-self="center">
                            <validation-provider v-slot="validationContext" vid="popular-name" name="Popular Name" rules="required">
                              <b-form-group label="Cell Phone"
                                            label-for="popular-name-input"
                                            label-class="mb-n05 font-weight-bold"
                                            class="m-0 p-0 mt-05"
                                            :invalid-feedback="validationContext.errors[0]">
                                <b-input v-model="selected.chaperone.phone.number"
                                         v-mask="'(###) ###-####'"
                                         placeholder="(555) 555-5555"
                                         :disabled="!state.editing"
                                         :class="['w-100', !state.editing ? 'form-control-plaintext' : 'form-control-plaintext']"
                                         :state="getValidationState(validationContext)"/>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </b-col>
                </b-row>

                <!-- Students -->
                <b-tabs v-model="studentEvents.tab" nav-class="d-flex flex-row justify-content-start">
                  <b-tab title="Students">
                    <students-tab :selected="selected"
                                  :hotels="hotels"
                                  :state="state"
                                  :sorting="studentEvents.sorting"
                                  @sorted="(sorting) => studentEvents.sorting = sorting"/>
                  </b-tab>
                  <b-tab title="Bed Checks">
                    <bed-checkin-tab :selected="selected"
                                     :hotels="hotels"
                                     :state="state"
                                     :sorting="studentEvents.sorting"
                                     @sorted="(sorting) => studentEvents.sorting = sorting"
                                     @update="(studentEvent) => updateStudentEventCheckin(studentEvent)"/>
                  </b-tab>
                  <template #tabs-end>
                    <template v-if="selected.chaperone.studentEvents.items.length">
                      <b-button v-if="studentEvents.tab === 1" variant="link" class="ml-auto" @click="openModal('chaperone-checkins')">
                        <b-icon-printer style="width: 14px; height: 14px"/>
                      </b-button>
                      <bed-checkin-modal id="chaperone-checkins"
                                         :chaperones="[selected.chaperone]"
                                         :events="studentEvents.items.filter(e => e.chaperoneId === selected.chaperone.id)"
                                         :sorting="studentEvents.sorting"
                      />
                    </template>
                  </template>
                </b-tabs>

              </form>
            </validation-observer>
          </template>
        </template>
      </event-layout>
    </template>

    <template #debug>
      <b-row>
        <b-col>
          <debug title="Chaperones" collapsed>{{ chaperones }}</debug>
        </b-col>
        <b-col>
          <debug title="Selected Chaperone" collapsed>{{ selected.chaperone }}</debug>
        </b-col>
        <b-col>
          <debug title="Student Events" collapsed>{{ studentEvents }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import PageLayout from '@/components/PageLayout.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import Fuse from 'fuse.js';
import ZipInput from '@/components/ZipInput.vue';
import StateInput from '@/components/StateInput.vue';
import vSelect from 'vue-select';
import DateOfBirth from '@/components/DateOfBirth.vue';
import LastModified from '@/components/LastModified.vue';
import {VueMaskDirective} from 'v-mask';
import events from '@/mixins/event.mixin'
import OverlayLoading from '@/components/OverlayLoading.vue';
import { getHotels, getChaperones, createChaperone, updateChaperone, deleteChaperone, getStudentEventsByChaperoneId, updateStudentEvent } from '@/views/events/service';
import { prefixes, roles, yesNo } from '@/views/events/service/data';
import notify from '@/mixins/notify.mixin';
import SortInput from '@/components/SortInput.vue';

import EventsNav from '@/views/events/EventsNav.vue';
import {cascadeConfirmDeleteOptions, cascadeDeleteChaperone } from '@/graphql/cascade/chaperone';
import EventLayout from '@/views/events/EventLayout.vue';
import StudentsTab from '@/views/events/chaperones/StudentsTab.vue';
import BedCheckinTab from '@/views/events/chaperones/BedCheckinTab.vue';
import BedCheckinModal from '@/views/events/chaperones/BedCheckinModal.vue';
import _ from 'lodash';


/* eslint-disable global-require */
export default {
  name: 'Chaperones',
  directives: { mask: VueMaskDirective },
  components: {
    PageLayout,
    EventLayout,
    EventsNav,
    StudentsTab,
    BedCheckinTab,
    BedCheckinModal,

    SortInput,
    OverlayLoading,
    LastModified,
    StateInput,
    ZipInput,
    DateOfBirth,
    BCardActions,
    draggable,
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [ events, notify ],
  props: {
    id: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      selected: {
        chaperone: null,
        initialChaperone: null,
        loading: false,
      },
      chaperones: {
        loading: true,
        items: [],
        search: null,
        filters: {
          hotelId: null,
        },
        sorting: {
          show: false,
          by: ['name.last', 'name.first'],
          desc: false,
          drag: false,
          options: [
            { label: 'First Name', value: 'name.first'},
            { label: 'Last Name', value: 'name.last'},
          ]
        },
      },
      hotels: {
        loading: true,
        items: [ ],
      },
      studentEvents: {
        loading: true,
        items: [ ],
        tab: 0,
        sorting: {
          show: false,
          by: ['room', 'student.name.last'],
          desc: false,
          drag: false,
          options: [
            { label: 'Room', value: 'room'},
            { label: 'First Name', value: 'student.name.first'},
            { label: 'Last Name', value: 'student.name.last'},
          ]
        },
      },
      options: {
        roles: roles,
        prefixes: prefixes,
        yesNo: yesNo,
      },
      icon: 'fas fa-people-roof',
      image: require('@/assets/images/illustration/storyset-to-do-list-rafiki.svg'),
      emptyImage: require('@/assets/images/illustration/storyset-ui-ux-design-rafiki.svg'),
      sidebar: true,
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    computedChaperones() {
      const fuse = new Fuse(this.chaperones.items, {
        useExtendedSearch: true,
        keys: [
          'id',
          'name.first',
          'name.last',
          'hotelId'
        ]
      })

      const query = { $and: [ ] }
      if(this.chaperones.filters.hotelId) { query.$and.push({ hotelId: this.chaperones.filters.hotelId }) }
      if(this.chaperones.search) {
        query.$and.push({
          $or: [
            { 'name.first': `'${this.chaperones.search}` },
            { 'name.last': `'${this.chaperones.search}`}
          ]
        })
      }

      let items;
      if(query.$and.length) { items = fuse.search(query).map(({ item }) => item) }
      else { items = this.chaperones.items }
      return items
    },
    hasChanges() {
      return JSON.stringify(this.selected?.chaperone) !== JSON.stringify(this.selected?.initialChaperone)
    }
  },
  async created() {
    await this.listChaperones()
    await this.listHotels()
    await this.$nextTick(() => {
      if(this.id) this.selectChaperone(this.chaperones.items.find(chaperone => chaperone.id === this.id))
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
  },
  methods: {
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.listChaperones()
      this.$refs.layout.state.loading = false
    },

    async listHotels() {
      this.hotels.loading = true;
      await getHotels().then(hotels => {
        this.hotels.items = hotels;
        this.hotels.loading = false;
        this.hotels.loaded = true;
      })
    },

    async listChaperones() {
      this.chaperones.loading = true;
      await getChaperones({ includeHotel: true }).then(chaperones => {
        this.chaperones.items = chaperones;
        this.chaperones.loading = false;
        this.chaperones.loaded = true;
      })
    },
    async addChaperone() {
      await createChaperone().then(chaperone => {
        chaperone.studentEvents = { loading: true, items: [] }
        this.chaperones.items.push(chaperone)
        this.selected.chaperone = chaperone
        this.notify({ title: 'Success', text: 'Chaperone was successfully created', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Chaperone failed to create', icon: this.icon, variant: 'danger'});
      })
    },
    async updateChaperone() {
      const { id, name, phone, role, hotelId, room } = this.selected.chaperone
      await updateChaperone({ id, name, phone, role, hotelId, room }).then(() => {
        this.updateSelectedChaperoneInitial()
        this.notify({ title: 'Success', text: 'Chaperone was successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Chaperone failed to update', icon: this.icon, variant: 'danger'});
      })
    },
    async deleteChaperone(chaperone, swalCallback) {
      try {
        await this.cascadeDeleteChaperone(chaperone.id, swalCallback)
        this.chaperones.items = this.chaperones.items.filter(c => c.id !== chaperone.id)
        this.selected.chaperone = null
        this.notify({ title: 'Success', text: 'Chaperone was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Chaperone failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
    },
    cascadeDeleteChaperone,

    async selectChaperone(chaperone) {
      this.selected.loading = true;

      if(!chaperone?.studentEvents?.items?.length) {
        chaperone.studentEvents = { loading: true, items: [] }
        await getStudentEventsByChaperoneId(chaperone.id).then(students => {
          chaperone.studentEvents.items = students;
          chaperone.studentEvents.loading = false;
        })
      }
      this.selected.chaperone = chaperone;
      this.selected.initialChaperone = JSON.parse(JSON.stringify(chaperone));
      this.selected.loading = false;
      if(this.$route.params.id !== chaperone.id) {
        await this.$router.push({ name: 'events-chaperone', params: { id: chaperone.id } })
      }
    },
    async deselectChaperone() {
      this.selected.chaperone = null;
      this.selected.initialChaperone = null
      if(this.$route.params.id !== null) {
        await this.$router.push({ name: 'events-chaperones', params: { id: null } })
      }
    },
    async resetSelectedChaperone() {
      // Deep clone the initialBus object to selected chaperone.
      this.selected.chaperone = JSON.parse(JSON.stringify(this.selected.initialChaperone));
      // Find the index of the bus with the matching id in the buses array
      const index = this.chaperones.items.findIndex(chaperone => chaperone.id === this.selected.chaperone.id);

      // Replace the bus at the found index with the selected bus
      if (index !== -1) {
        this.chaperones.items.splice(index, 1, this.selected.chaperone);
      }
    },
    updateSelectedChaperoneInitial() {
      this.selected.initialChaperone = JSON.parse(JSON.stringify(this.selected.chaperone))
    },

    async updateStudentEventCheckin(studentEvent) {
      const { id, checkin } = studentEvent
      await updateStudentEvent({ id, checkin }).then(() => {
        this.notify({ title: 'Success', text: 'Checkin was successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Checkin failed to update', icon: this.icon, variant: 'danger'});
      })
    },

    openModal(modalId) {
      this.$bvModal.show(modalId)
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  },
}
</script>

<style scoped lang="scss">

.ps-chaperones {
  max-height: 50vh;
}

.ps-chaperones .list-group .list-group-item:not(.active) {
  background: #f8f8f8;
}
.ghost {
  background: #f8f8f8;
}
.draggable-list-group .list-group-item:hover {
  background-color: inherit;
}

.draggable-list-group.list-group .list-group-item:hover {
  background-color: unset!important;
}

.list-group:empty,
.list-group > div:empty {
  padding:1rem;
  margin-right: .5rem;
  text-align:left;
  border: 1px dashed rgba(34, 41, 47, 0.125);
}

.students.list-group:empty:before,
.students.list-group > div:empty:before {
  content: 'No Students Selected...';
}

.chaperones.list-group:empty:before,
.chaperones.list-group > div:empty:before {
  content: 'No Chaperones Selected...';
}

.carousel-1___BV_indicators_ li {
    cursor: pointer;
    background-color: green;
}

#carousel-1 .carousel-caption {
  color: red
}


.col.pr-75 {
  padding-right: 0.75rem;
}
</style>
