<template>
  <validation-observer ref="observer" tag="form">
    <can do="read" on="all-state-application[tab=student]" field="info">
      <b-row v-if="showHeading">
        <b-col cols="12" class="mb-2">
          <b-row>
            <b-col>
              <h5 class="mb-0">Student Information</h5>
              <small class="text-muted">Enter Your Personal Info.</small>
            </b-col>
            <b-col cols="auto">
              <v-select v-if="existingStudentsOptions.show"
                        v-model="application.student.id"
                        input-id="existing-students-dropdown"
                        label="name"
                        :reduce="student => student.id"
                        :options="students"
                        :filter="searchStudents"
                        :loading="options.students.loading"
                        :select-on-tab="true"
                        :disabled="!editable"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        placeholder="Select Existing Student"
                        class="v-select-inline"
                        :selectable="option => !option.divider"
                        :clearable="existingStudentsOptions.clearable"
                        @option:selected="selectStudent"
                        @input="onSelectExistingStudent">
                <template #option="{ name, school, divider }">
                  <template v-if="divider">
                    <hr class="mt-0">
                    <h6>Students Submitted By Other Teachers</h6>
                  </template>
                  <template v-else>
                    <h6 class="mb-0">{{ name.first }} {{ name.last }}</h6>
                    <small v-if="school && school.name">{{ school.name.legal }}</small>
                    <small v-else class="text-danger">No School</small>
                  </template>
                </template>
                <template #selected-option="{ name }">
                  {{ name.first }} {{ name.last }}
                </template>
              </v-select>

              <template v-if="createStudent">
                <small class="px-1">or</small>
                <b-button id="new-student-btn" variant="primary" @click="newStudent({ disabled: false })">New Student</b-button>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
				<b-col v-if="(studentMayExist || studentLikelyExists || studentAlreadyExists) && !application.student.id" cols="12">
					<b-alert v-if="studentMayExist && !studentLikelyExists && !studentAlreadyExists" show variant="secondary">
						<h6 class="text-secondary">Student May Already Exist</h6>
						<small class="d-block">Check the Existing Student dropdown to avoid creating a duplicate Student record.</small>
					</b-alert>
					<b-alert v-if="studentLikelyExists && !studentAlreadyExists" show variant="warning">
						<h6 class="text-warning">Student Likely Already Exists</h6>
						<small class="d-block">Another teacher at your school may have already submitted an application for this student.</small>
						<small class="d-block">Check the Existing Student dropdown to avoid creating a duplicate Student record.</small>
					</b-alert>
					<b-alert v-if="studentAlreadyExists" show variant="danger">
						<h6 class="text-danger">Student Already Exists!</h6>
						<small class="d-block">It seems unlikely two students with the same name, go to the same school and either have the same birthdate or live at the same address...</small>
						<small>Check the Existing Student dropdown to avoid creating a duplicate Student record.</small>
					</b-alert>
				</b-col>
        <b-col md="6">
          <b-form-group label="First Name" label-for="first-name">
            <validation-provider #default="{ errors }" vid="first-name" name="First Name" :rules="rules.firstName">
              <b-form-input id="first-name"
                            v-model="application.student.name.first"
                            :disabled="options.student.disabled"
                            :state="errors.length > 0 ? false:null"
														:placeholder="options.student.disabled ? '' : 'John'"
                            autocomplete="off"
                            @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Last Name" label-for="last-name">
            <validation-provider #default="{ errors }" vid="last-name" name="Last Name" :rules="rules.lastName">
              <b-form-input id="last-name"
                            v-model="application.student.name.last"
                            :disabled="options.student.disabled"
                            :state="errors.length > 0 ? false:null"
														:placeholder="options.student.disabled ? '' : 'White'"
                            autocomplete="off"
                            @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <validation-provider #default="{ errors }" vid="school" name="School" :rules="rules.school">
            <b-form-group label="School" label-for="school" :state="errors.length > 0 ? false:null">
              <v-select v-model="application.student.school"
                        input-id="school"
                        :loading="options.schools.loading"
                        :options="schools" label="name"
                        :reduce="school => ({ id: school.id, name: school.name })"
                        :filter="searchSchools"
                        :select-on-tab="true"
                        :disabled="options.student.disabled"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="syncStudent">
                <template #option="{ name }">
                  <template v-if="name">
                    <h6 class="mb-0">{{ name.legal }}</h6>
                    <small>{{ name.popular }}</small>
                  </template>
                  <template v-else>
                    <h6 class="mb-0 text-danger">No School</h6>
                  </template>
                </template>
                <template #selected-option="{ name }">
                  <template v-if="name">
                    {{ name.legal }}
                  </template>
                  <template v-else>
                    <h6 class="text-danger">No School</h6>
                  </template>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider #default="{ errors }" vid="grade" name="Grade" :rules="rules.grade">
            <b-form-group label="Grade" label-for="grade" :state="errors.length > 0 ? false:null">
              <v-select v-model="application.student.grade"
                        input-id="grade"
                        :options="options.grades"
                        :select-on-tab="true"
                        :disabled="options.student.disabled"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @input="syncStudent"/>
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider #default="{ errors }" vid="dob" name="Date of Birth" :rules="rules.dob">
            <b-form-group label="Date of Birth" label-for="dob-input">
                <flat-pickr id="dob-input"
                            ref="student-dob"
                            v-model="application.student.dob"
                            v-cleave="config.cleave"
                            :config="config.pickr"
                            :disabled="options.student.disabled"
                            placeholder="MM/DD/YYYY"
                            :class="['form-control', errors.length ? 'is-invalid' : null]"
                            @on-change="syncStudent"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
    </b-row>
    </can>
    <can do="read" on="all-state-application[tab=student]" field="address">
      <hr/>
      <b-row>
        <b-col md="6">
          <b-form-group label="Line 1" label-for="line1">
            <validation-provider #default="{ errors }" vid="line1" name="line 1" :rules="rules.line1">
              <b-form-input
                  id="line1"
                  v-model="application.student.address.line1"
                  :disabled="options.student.disabled"
                  :state="errors.length > 0 ? false:null"
									:placeholder="options.student.disabled ? '' : '98 Borough Bridge Road'"
                  autocomplete="off"
                  @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Line 2" label-for="line2">
            <validation-provider #default="{ errors }" vid="line2" name="Line 2" :rules="rules.line2">
              <b-form-input
                  id="line2"
                  v-model="application.student.address.line2"
                  :disabled="options.student.disabled"
                  :state="errors.length > 0 ? false:null"
									:placeholder="options.student.disabled ? '' : 'Apartment 123'"
                  autocomplete="off"
                  @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="City" label-for="city">
            <validation-provider #default="{ errors }" vid="city" name="City" :rules="rules.city">
              <b-form-input
                  id="city"
                  v-model="application.student.address.city"
                  :disabled="options.student.disabled"
                  :state="errors.length > 0 ? false:null"
									:placeholder="options.student.disabled ? '' : 'Albany'"
                  autocomplete="off"
                  @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="State" label-for="state">
            <validation-provider #default="{ errors }" vid="state" name="State" :rules="rules.state">
              <state-input id="state"
                           v-model="application.student.address.state"
                           placeholder="NY"
                           :disabled="options.student.disabled"
                           :validation-state="errors.length > 0 ? false:null"
                           @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Zip Code" label-for="zip">
            <validation-provider #default="{ errors }" vid="zip" name="Zip Code" :rules="rules.zip">
              <zip-input id="zip"
                         v-model="application.student.address.zip"
                         :disabled="options.student.disabled"
                         :validation-state="errors.length > 0 ? false:null"
                         @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </can>
    <can do="read" on="all-state-application[tab=student]" field="contact">
      <hr/>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="phone">Cell Phone</label>
              <template v-if="editable">
								<b-icon-question-circle id="phone-help" />
								<b-popover target="phone-help" triggers="hover" placement="left">
									<small>Please enter a cell phone so that student's can be directly contacted by Ensemble Chairs and Chaperones.</small>
								</b-popover>
              </template>
            </div>
            <validation-provider #default="{ errors }" vid="phone" name="Cell Phone Number" :rules="rules.phone">
              <b-form-input
                  id="phone"
                  v-model="application.student.phone.number"
                  v-mask="'(###) ###-####'"
                  :disabled="options.student.disabled"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="options.student.disabled ? '' : '(555) 555-5555'"
                  autocomplete="off"
                  @input="syncStudent"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="email">Personal Email</label>
							<template v-if="editable">
								<b-icon-question-circle id="email-help" />
								<b-popover target="email-help" triggers="hover" placement="left">
									<small>
										Please enter the student's personal email address. Some schools do not allow external email into their networks,
										which would make it impossible to contact the student by email.
									</small>
								</b-popover>
              </template>
            </div>
            <validation-provider #default="{ errors }" vid="email" name="Personal Email" :rules="rules.email">
              <b-form-input
                  id="email"
                  v-model="application.student.email.address"
                  :disabled="options.student.disabled"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="options.student.disabled ? '' : 'student@email.com'"
                  autocomplete="off"
                  @input="syncStudent"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </can>
  </validation-observer>
</template>

<script>
import {TabContent} from 'vue-form-wizard';
import vSelect from 'vue-select';
import { VueMaskDirective } from 'v-mask'
import {API, graphqlOperation} from 'aws-amplify';
import {listSchools, listStudents, listStudentsBySchool} from '@/graphql/queries/application';
import Fuse from 'fuse.js';
import DateOfBirth from '@/components/DateOfBirth.vue';
import {uuid} from 'vue-uuid';
import {rules} from '@/data/validation-rules';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'cleave.js';
import {studentSchools, updateStudent} from '@/views/dev/fix-student-schools';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'ApplicationStudent',
  directives: {
    mask: VueMaskDirective,
    cleave: {
      inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
      },
    }
  },
  components: {
    ZipInput,
    StateInput,
    DateOfBirth,
    TabContent,
    vSelect,
    flatPickr
  },
  props: {
    currentUser: {
      type: Object,
      default: null
    },
    student: {
      type: Object,
      default: null
    },
    createStudent: {
      type: Boolean,
      default: true
    },
    existingStudentsOptions: {
      type: Object,
      default: () => ({
        show: true,
        clearable: true
      })
    },
    editable: {
      type: Boolean,
      default: true
    },
    showHeading: {
        type: Boolean,
        default: true
    }
  },
  mixins: [ settingsMixin ],
  data() {
    return {
      user: this.currentUser,
      application: {
        student: {
          id: this.student?.id || null,
          name: {
            first: this.student?.name?.first || null,
            last: this.student?.name?.last || null
          },
          dob: this.student?.dob || '',
          sex: this.student?.sex || null,
          grade: this.student?.grade || null,
          school: this.student?.school || null,
          address: {
            line1: this.student?.address?.line1 || null,
            line2: this.student?.address?.line2 || null,
            city: this.student?.address?.city || null,
            county: this.student?.address?.county || null,
            state: this.student?.address?.state || null,
            zip: this.student?.address?.zip || null
          },
          phone: {
            type: this.student?.phone?.type || null,
            number: this.student?.phone?.number || null
          },
          email: {
            type: this.student?.email?.type || null,
            address: this.student?.email?.address || null
          }
        },
      },
      options: {
        student: {
          showChoices: true,
          disabled: true
        },
        students: {
          items: [],
          selectedItem: null,
          loading: false,
          loaded: false
        },
        schools: {
          loading: false,
          items: [],
        },
        grades: [10, 11],
      },
      rules: {
        school: { required: true },
        firstName: { required: true },
        lastName: { required: true },
        dob: {
          required: true,
          regex: /^(0[1-9]|1[012])[/](0[1-9]|[12]\d|3[01])[/](19|20)\d\d$/
        },
        grade: { required: true },
        sex: { required: true },
        line1: { required: true },
        line2: { required: false },
        city: {
          required: true,
          alpha_spaces: true,
          max: 48
        },
        state: {
          required: true,
          alpha: true,
          min: 2,
          max: 2
        },
        zip: {
          required: true,
          regex: /^\d{5}(?:[-\s]\d{4})?$/
        },
        phone: { required: true },
        email: { required: true, email: true }
      },
      config: {
        cleave: { date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' },
        pickr: { allowInput: true, clickOpens: false, dateFormat: 'm/d/Y'}
      },
    }
  },
  computed: {
    studentMayExist() {
      if(!this.options.students.loading) {
        return this.options.students.items.some(student => this.application.student.name.first === student.name.first && this.application.student.name.last === student.name.last)
      }
      return false
    },
    studentLikelyExists() {
      if(!this.options.students.loading) {
        return this.options.students.items.some(student =>
            this.application.student.name.first === student.name.first
            && this.application.student.name.last === student.name.last
            && this.application.student.school?.id === student.school?.id)
      }
      return false
    },
    studentAlreadyExists() {
      if(!this.options.students.loading) {
        return this.options.students.items.some(student =>
            this.application.student.name.first === student.name.first
            && this.application.student.name.last === student.name.last
            && this.application.student.school?.id === student.school?.id
            && (this.application.student.dob === student.dob || this.application.student.address.line1 === student.address?.line1 ))
      }
      return false
    },
    initialDOB() {
      const gradeYears = this.application.student.grade === 11 ? 17 : 16
      return new Date(new Date().getFullYear() - gradeYears, 0, 1)
    },
    schools() {
      if(this.options.student.disabled) {
        return []
      }
      if(this.options.schools.items.length === 0) {
        this.listSchools()
      }
      return this.options.schools.items
    },
    students() {
      if(this.options.students.loading) return []
      if(this.editable && !this.options.students.loaded && this.existingStudentsOptions.show && this.settingsStore.app) {
          if(this.user.groups.includes('Teacher')) {
              this.listStudentsForTeacher()
          }
          else {
              this.listAllStudents()
          }
          return []
      }
      return this.options.students.items
    }
  },
  methods: {
    async listAllStudents(nextToken, pagedStudents) {
      this.options.students.loading = true

      const students = pagedStudents || []
      const response = await API.graphql(graphqlOperation(listStudents, {
        limit: 500,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }));
      students.push(...response.data.listStudents.items);

      if(response.data.listStudents.nextToken) {
        await this.listAllStudents(response.data.listStudents.nextToken, students)
      }
      else {
        const mappedStudents = students.map(student => ({...student,
          name: {
            first: student.name.first,
            last: student.name.last,
            full: `${student.name.first} ${student.name.last}`
          }
        }))
        this.options.students.items = mappedStudents.sort(this.compareStudents);
        this.options.students.loading = false
        this.options.students.loaded = true
      }
    },

    async listStudentsForTeacher() {
      this.options.students.loading = true
      const teacherSchools = this.user.schools.items.map(item => item.school.id)
      const students = []

      await teacherSchools.reduce(async (referencePoint, id) => {
        try {
          await referencePoint;
          const response = await API.graphql(graphqlOperation(listStudentsBySchool, {
            schoolID: id,
            filter: {
              createdAt: {
                between: [
                  this.settingsStore.app.current.year.start,
                  this.settingsStore.app.current.year.end
                ]
              }
            }
          }))
          students.push(...response.data.listStudentsBySchool.items.map(student => ({ ...student, name: { first: student.name.first, last: student.name.last, full: `${student.name.first} ${student.name.last}`} })));
        }
        catch (e) { console.error(e) }
      }, Promise.resolve());

      const teacherStudents = students.filter(student => student.applications.items.some(application => application.teacherID === this.user.id)).sort(this.compareStudents)
      const nonTeacherStudents = students.filter(student => !teacherStudents.map(ts => ts.id).includes(student.id) ).sort(this.compareStudents)

      this.options.students.items.push(...teacherStudents)
      this.options.students.items.push({ id: uuid.v4(), divider: true, name: { first: '----------', last: '-----------'}, school: { name: { legal: null }}})
      this.options.students.items.push(...nonTeacherStudents.sort(this.compareStudents))
      this.options.students.loading = false
      this.options.students.loaded = true
      return students
    },

    compareStudents(a, b) {
      return a.name?.first.localeCompare(b.name?.first) || a.name?.last.localeCompare(b.name?.last) || a.school?.name?.legal
    },

    async listSchools(nextToken, pagedSchools) {
      this.options.schools.loading = true
      if(this.user.groups.includes('Teacher')) {
        this.options.schools.items.push(...this.user.schools.items.map(item => item.school))
        this.options.schools.loading = false
      }
      else {
        const schools = pagedSchools || []
        const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
        schools.push(...response.data.listSchools.items);

        if(response.data.listSchools.nextToken) {
          await this.listSchools(response.data.listSchools.nextToken, schools)
        }
        else {
          this.options.schools.items = schools.sort((a, b) => a.name?.legal.localeCompare(b.name?.legal))
          this.options.schools.loading = false
        }
      }
    },

    syncStudent() {
      this.$emit('update:student', this.application.student)
    },
    onSelectExistingStudent(input) {
      if(!input) {
        this.newStudent({ disabled: true })
      }
      this.syncStudent()
    },

    selectStudent(option) {
      this.options.student.disabled = true;
      this.application.student.name = option.name
      this.application.student.dob = option.dob
      this.application.student.sex = option.sex
      this.application.student.grade = option.grade
      this.application.student.school = option.school
      this.application.student.address = option.address
      this.application.student.phone = option.phone
      this.application.student.email = option.email
    },

    searchStudents(options, search) {
      const fuse = new Fuse(options, {
        keys: [
          'name.first', 
          'name.last',
          'name.full'
        ],
        threshold: 0.2,
        shouldSort: true,
        useExtendedSearch: true
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },

    searchSchools(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },

    newStudent(options = { disabled: false }) {
      this.application.student = {
        id: null,
        name: {
          first: null,
          last: null
        },
        dob: '',
        sex: null,
        grade: null,
        school: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          county: null,
          state: null,
          zip: null
        },
        phone: {
          type: null,
          number: null
        },
        email: {
          type: null,
          address: null
        }
      }
      this.options.student.disabled = options.disabled
      this.$refs.observer.reset()
      this.syncStudent()
    },

    disabledDOB(ymd, date) {
      const gradeYears = this.application.student.grade === 11 ? 17 : 16
      const currentYear = new Date().getFullYear()
      const year = date.getFullYear()
      return year < (currentYear - gradeYears) || year > (currentYear - gradeYears)
    },
  }
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
  .v-select-inline {
    display: inline-block;
    width: auto;
    min-width: 30rem;
  }

  [dir] .vs__dropdown-menu {
    width: 100%!important;
  }
</style>
