<template>
  <b-card id="events-nav-card" no-body>
    <b-card-body class="pt-1 pb-0">
      <b-card-title class="mb-1">
        <b-row>
          <b-col align-self="center" class="d-flex justify-content-between align-items-center">
            <div>
              {{ title }}
            </div>
            <div>
              <slot name="actions"></slot>
              <b-badge v-if="isFiltered" title="Filtered Items" variant="light-primary" pill class="ml-25">{{ items.length }}</b-badge>
              <b-dropdown id="events-nav-card-dropdown"
                          title="Options"
                          no-caret dropleft :right="$store.state.appConfig.isRTL"
                          variant="link"
                          toggle-class="btn-icon pr-0" menu-class="events-nav-card-dropdown-menu">
                <template #button-content>
                  <b-icon-three-dots-vertical style="width: 14px; height: 14px;"/>
                </template>
                <b-dropdown-form form-class="p-50">
                  <b-dropdown-item v-if="filters" @click="ui.filters.visible = !ui.filters.visible">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <b-icon :icon="ui.filters.visible ? 'funnel-fill' : 'funnel'"/>
                        <span class="ml-1">Filters</span>
                      </div>
                      <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill>{{ activeFilters.length }}</b-badge>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="sorting" @click="ui.sorting.visible = !ui.sorting.visible">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <b-icon-sort-down/>
                        <span class="ml-1">Sorting</span>
                      </div>
                      <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill>{{ sorting.by.length }}</b-badge>
                    </div>

                  </b-dropdown-item>
                  <slot name="options"></slot>
                </b-dropdown-form>
              </b-dropdown>
            </div>
          </b-col>
          <b-col v-if="showSearch" align-self="center" cols="12" class="pt-1">
            <b-input v-model="search" type="search" size="" placeholder="Search..." :debounce="200" @input="(text) => onSearch(text)"/>
          </b-col>
        </b-row>
      </b-card-title>
    </b-card-body>

    <b-card-body v-if="ui.filters.visible || ui.sorting.visible" class="py-0">
      <template v-if="ui.filters.visible && filters">
        <b-card bg-variant="light-primary" body-class="px-1" class="mb-75">
          <div class="font-weight-bold font-small-3 d-flex align-self-center justify-content-between">
            <span v-b-toggle="'filters-collapse'">Filters</span>
            <div class="d-flex align-items-center">
              <b-badge v-if="isFiltered" title="Active Filters" variant="primary" pill class="mr-1">{{ activeFilters.length }}</b-badge>
              <b-icon-x v-else style="width: 15px; height: 15px;"
                        class="cursor-pointer mr-1"
                        @click="ui.filters.visible = false"/>
              <b-icon-chevron-down style="width: 14px; height: 14px;"
                                   class="cursor-pointer"
                                   :class="ui.filters.expanded ? 'rotate-180' : 'collapsed'"
                                   aria-controls="filters-collapse"
                                   :aria-expanded="ui.filters.expanded ? 'true' : 'false'"
                                   @click="ui.filters.expanded = !ui.filters.expanded "/>
            </div>
          </div>
          <b-collapse id="filters-collapse" v-model="ui.filters.expanded" class="mt-1">
            <slot name="filters"></slot>
          </b-collapse>
        </b-card>
      </template>
      <template v-if="ui.sorting.visible">
        <b-card bg-variant="light-primary" body-class="px-1" class="mb-75">
          <div class="font-weight-bold font-small-3 d-flex justify-content-between align-items-center">
            <span v-b-toggle="'sorting-collapse'">Sorting</span>
            <div class="d-flex align-items-center">
              <b-badge v-if="sorting.by.length" title="Active Sorting" variant="primary" pill class="mr-1">{{ sorting.by.length }}</b-badge>
              <b-icon-x v-else style="width: 15px; height: 15px;"
                        class="cursor-pointer mr-1"
                        @click="ui.sorting.visible = false"/>
              <b-icon-chevron-down style="width: 14px; height: 14px;"
                                   class="cursor-pointer"
                                   :class="ui.sorting.expanded ? 'rotate-180' : 'collapsed'"
                                   aria-controls="sorting-collapse"
                                   :aria-expanded="ui.sorting.expanded ? 'true' : 'false'"
                                   @click="ui.sorting.expanded = !ui.sorting.expanded "/>
            </div>
          </div>
          <b-collapse id="sorting-collapse" v-model="ui.sorting.expanded" class="mt-1">
           <sort-input v-model="sorting" class="mt-1" @input="onSort(sorting)"/>
          </b-collapse>
        </b-card>
      </template>
    </b-card-body>

    <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-events-nav" :class="scrollbarClass">
      <b-card-body class="py-0">
        <b-list-group flush>
          <b-list-group-item v-for="(item, index) in computedItems" :key="getKeyVal(item, itemsKey)"
                             :active="isSelected(item)"
                             :class="['rounded border-0 shadow-bottom', index === 0 ? 'mt-0' : 'mt-50']"
                             style="height: 48px"
                             :disabled="isDisabled(item)"
                             @click="selectItem(item)">
            <b-row class="h-100">
              <b-col align-self="center" class="font-small-4 event-nav-item">
                <slot name="item"
                      :item="item"
                      :isSelected="isSelected(item)"
                      :emptyTextClass="[isSelected(item) ? 'text-secondary' : 'text-danger', 'font-italic font-small-3']"
                ></slot>
              </b-col>
              <b-col v-if="isDisabled(item)" align-self="center" cols="auto" class="px-0">
                <b-button size="sm" variant="link">
                  <b-icon-exclamation-circle-fill variant="danger" scale="1"/>
                </b-button>
              </b-col>
              <b-col v-if="isSelected(item)" align-self="center" cols="auto" class="px-0">
                <b-button size="sm" variant="link" @click.prevent.stop="deselectItem()">
                  <b-icon icon="x" scale="1.25" class="text-white"/>
                </b-button>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </vue-perfect-scrollbar>

    <b-card-footer class="pb-50 border-0"/>
  </b-card>
</template>

<script>
import vSelect from 'vue-select';
import SortInput from '@/components/SortInput.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import _ from 'lodash';

export default {
  name: 'EventsNav',
  components: {
    SortInput,
    vSelect,
    VuePerfectScrollbar
  },
  props: {
    title: {
      type: String,
      required: true
    },
    selectedId: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    itemsKey: {
      type: String,
      default: 'id'
    },
    filters: {
      type: Object,
      default: () => null
    },
    sorting: {
      type: Object,
      default: () => ({
        by: [],
        desc: false,
        drag: false,
        options: []
      })
    },
    scrollbarClass: {
      type: String,
      default: null
    },
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: null,
      search: null,
      ui: {
        filters: {
          visible: false,
          expanded: false
        },
        sorting: {
          visible: false,
          expanded: false
        }
      }
    }
  },
  computed: {
    computedItems() {
      if (this.sorting?.by?.length) {
        return this.sortItems(this.sorting, this.items);
      }
      return this.items;
    },
    isFiltered() {
      return this.search || (this.filters && Object.values(this.filters).some(f => f !== null));
    },
    activeFilters() {
      return Object.values(this.filters).filter(f => f !== null);
    }
  },
  watch: {
    selectedId(value) {
      if(!value) {
        this.deselectItem()
      }
    },
  },
  mounted() {
    if(this.selectedId) {
      this.selected = this.items.find(i => i.id === this.selectedId);
    }
  },
  methods: {
    selectItem(item) {
      this.selected = item;
      this.$emit('select', item);
    },
    async deselectItem() {
      /*if(this.$route.params.id !== null) {
        await this.$router.push({ name: this.$route.name, params: { id: null } })
      }*/
      this.$emit('deselect');
      this.selected = null;
    },
    onSearch(text) {
      this.$emit('search', text);
    },
    onSort(sorting) {
      this.$emit('sort', sorting);
    },
    isSelected(item) {
      return this.$route.params?.id === item.id;
    },
    isDisabled(item) {
      return this.items.filter(i => i.id === item.id).length > 1;
    },
    sortItems(sorting, items) {
      const { by, desc } = sorting;
      const order = desc ? 'desc' : 'asc';
      const orders = []; // Array to hold the order (asc/desc) for each criteria

      for (let i = 0; i < by.length; i++) {
        orders.push(order);
      }

      return _.orderBy(items, by, orders);
    },
    getKeyVal(item, key) {
      return _.get(item, key);
    }
  }
}
</script>



<style scoped lang="scss">
.ps-events-nav {
  max-height: 25vh;

  .list-group {
    .list-group-item:not(.active) {
      background: #f8f8f8;
    }
    .list-group-item:hover {
      background: #f8f8f8;
      cursor: pointer;
    }
    .list-group-item.active:hover {
      background: var(--primary);
      cursor: pointer;
    }
  }
}
</style>
