/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';
import {cascadeDeleteSelection} from '@/graphql/cascade/selection';

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this Application may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Selections associated with this Application will be deleted.<br/>
            This may effect scores, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'Application was successfully deleted.' }
}

export async function cascadeDeleteApplication(applicationId, swalCallback) {
    try {
        /** Delete Selection records (1) associated to this Application
         * Note: This is a recursive call to the cascadeDeleteSelection function
         * which will delete the Selection record and update all Application records associated to the Selection (removing the connection).
         **/
        const selections = await listSelections(applicationId, swalCallback)
        await batchProcessWithRetry(selections, async (selection) => {
            await cascadeDeleteSelection(selection.id, swalCallback)
        }, { title: 'Updating Selections', callback: swalCallback })

        /** Delete Application **/
       await deleteApplication(applicationId, swalCallback)
    }
    catch(e) {
        console.error(e)
    }
}

/** Helper Functions **/
async function deleteApplication(applicationId, swalCallback) {
    const title = 'Deleting Application'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    await API.graphql(graphqlOperation(deleteApplicationMutation, { input: { id: applicationId }} ));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listSelections(applicationId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { selectionApplicationId: { eq: applicationId } } }
            const response = await API.graphql(graphqlOperation(listSelectionsQuery, input));
            items.push(...response.data.listSelections.items)
            nextToken = response.data.listSelections.nextToken;
            swalCallback(new SwalData('Loading Selections', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}

/** Queries & Mutations **/
const listSelectionsQuery = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                selectionApplicationId
            }
            nextToken
        }
    }
`;

const deleteApplicationMutation = /* GraphQL */ `
    mutation DeleteApplication(
        $input: DeleteApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        deleteApplication(input: $input, condition: $condition) {
            id
        }
    }
`;
